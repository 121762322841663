//funzioni comuni

export default {
  //formata una colonna booleana da vue good table
  formatBool(valore) {
    if (valore == 'True') {
      return 'Si'
    }
    if (valore == 'False') {
      return 'No'
    }
    return ''
  },
  getAMBIENTE() {
    var hostname = window.location.hostname.toUpperCase();
    if (process.env.NODE_ENV === 'development' ||
      hostname.includes('LOCALHOST') ||
      hostname.includes('127.0.0.1')) {
      return 'SVI';
    } else {
      if (hostname == 'SCT-OUTIL.REGIONE.VDA.IT') {
        return 'PRO';
      }
    }
    return 'PRE';
  },
  isCodiceFiscale(codfis) {
    var cf = codfis.toUpperCase();
    var cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    if (!cfReg.test(cf))
      return false;
    var set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    var s = 0;
    for (var i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0))
      return false;
    return true;
  },
  Left(str, n) {
    if (n <= 0)
      return "";
    else if (n > String(str).length)
      return str;
    else
      return String(str).substring(0, n);
  },
  Right(str, n) {
    if (n <= 0)
      return "";
    else if (n > String(str).length)
      return str;
    else {
      var iLen = String(str).length;
      return String(str).substring(iLen, iLen - n);
    }
  },
  //funzione per aprire dialog di Warning
  openDialogWarning(obj, ref, titolo, corpo) {
    obj.$refs[ref].titolo = titolo
    obj.$refs[ref].corpo = corpo
    obj.$refs[ref].apriDialogWarning = true
  },
  //funzione per aprire dialog di Confirm
  openDialogConfirm(obj, ref, titolo, corpo) {
    obj.$refs[ref].titolo = titolo
    obj.$refs[ref].corpo = corpo
    obj.$refs[ref].apriDialogConfirm = true
  },
  //funzione per aprire dialog di Warnign che non si chiude
  openDialogNoClose(obj, ref, titolo, corpo) {
    obj.$refs[ref].titolo = titolo
    obj.$refs[ref].corpo = corpo
    obj.$refs[ref].apriDialogNoClose = true
  },
  //funzione per aprire dialog con iframe
  openDialogIframe(obj, ref, title, src) {
    obj.$refs[ref].src = src
    obj.$refs[ref].title = title
    obj.$refs[ref].HtmlContents = ''
    obj.$refs[ref].apriDialogIframe = true

  },
  // vue-good-table
  // ordinamento se dd/mm/yyyy
  dataStringSort(x, y, col, rowX, rowY) {
    var sep = "_";
    var dataX = x.split("/");
    var dataY = y.split("/");
    dataX = dataX[2] + sep + dataX[1] + sep + dataX[0]
    dataY = dataY[2] + sep + dataY[1] + sep + dataY[0]
    // x - row1 value for column
    // y - row2 value for column
    // col - column being sorted
    // rowX - row object for row1
    // rowY - row object for row2
    return (dataX < dataY ? -1 : (dataX > dataY ? 1 : 0));
  }
}
