/* eslint-disable */
<template>
  <!-- Page Content -->
  <div class="container container-interno">

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Webinar SCT-Outil</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
            Webinar SCT-Outil
          </h3>
        </div>
      </div>
    </div>

    <div class="row spazio_sopra col-spazio_sotto">
      <div class="col-lg-12 mb-12">
        <p class="card-text">In questa sezione è possibile consultare le registrazioni dei webinar di SCT-Outil e dei
          relativi servizi a
          disposizione.</p>
      </div>

      <div class="col-lg-6 mb-6">
        <div class="card h-100">
          <h5 class="card-header">
            <strong>16 dicembre 2022</strong>
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <p class="card-text"><a target="_blank" :href="VUE_APP_URL_VIDEO_WEBINAR_2022_12_16" class="urlmail"><i
                    class="fas fa-film"></i>&nbsp;Video</a></p>
              <p class="card-text"><a target="_blank" :href="VUE_APP_URL_PRESENTAZ_WEBINAR_2022_12_16"
                  class="urlmail"><i class="fas fa-photo-video"></i>&nbsp;Presentazione</a></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-6">
        <div class="card h-100">
          <h5 class="card-header">
            <strong>14 maggio 2021</strong>
          </h5>
          <div class="card-body">
            <div class="table-responsive">
              <p class="card-text"><a target="_blank" :href="VUE_APP_URL_VIDEO_WEBINAR_2021_05_14" class="urlmail"><i
                    class="fas fa-film"></i>&nbsp;Video</a></p>
              <p class="card-text"><a target="_blank" :href="VUE_APP_URL_PRESENTAZ_WEBINAR_2021_05_14"
                  class="urlmail"><i class="fas fa-photo-video"></i>&nbsp;Presentazione</a></p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- /.container -->
</template>

<script>
export default {
  name: 'VideoDemo',
  props: {
    msg: String
  },
  data() {
    return {
      banner: {
        src: require('../../public/SCTProfessional/static/images/banner_demo.png')
      },
      VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
      VUE_APP_URL_VIDEO_WEBINAR_2021_05_14: process.env.VUE_APP_URL_VIDEO_WEBINAR_2021_05_14,
      VUE_APP_URL_PRESENTAZ_WEBINAR_2021_05_14: process.env.VUE_APP_URL_PRESENTAZ_WEBINAR_2021_05_14,
      VUE_APP_URL_VIDEO_WEBINAR_2022_12_16: process.env.VUE_APP_URL_VIDEO_WEBINAR_2022_12_16,
      VUE_APP_URL_PRESENTAZ_WEBINAR_2022_12_16: process.env.VUE_APP_URL_PRESENTAZ_WEBINAR_2022_12_16
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>

</style>
