import Auth from '@/services/api/Auth_User.js'

export default {
  // restituisce lo user corrente e tutte le sue informazioni
  getUser(obj) {
    let errorgu = '';
    Auth.getAuthUserData()
      .then(data => {
        obj.user = data;
      })
      .catch(function (error) {
        errorgu = error
      })
      .finally(() => {
        if (errorgu !== '') {
          console.error(errorgu)
        }
      })
  },
};
