<template>
<!--
  <div v-if="apriFinestraCookies==true" class="ginger_banner-wrapper">
-->
  <div v-bind:class="classEUCookies">
    <div class="ginger_banner top light ginger_container ginger_container--open"><a v-on:click="accettoCookies" href="#"
        class="ginger_btn ginger-accept ginger_btn_accept_all">OK</a>
      <p class="ginger_message">
        <!--googleoff: index-->
      </p>
      <p style="text-align: center;">Questo sito consente l'invio di cookie di terze parti.<br><br>Se acconsenti all'uso
        dei cookie fai click su OK, se vuoi saperne di più o negare il consenso ai cookie vai alla <span
          style="text-decoration: underline;"> <a class="hrefcookies" 
          :href="VUE_APP_HREFCOOKIES">Pagina informativa</a></span></p>
      <!--googleon: index-->
    </div>
  </div>
</template>

<script>
  //cookiechoice:
  //per la gestione dei cookies
  import Vue from 'vue'  
  Vue.use(require('vue-cookies'))

  export default {
    name: 'CookieChoice',
    data() {
      return {
        //apriFinestraCookies:true,
        classEUCookies:"ginger_banner-wrapper",
        VUE_APP_HREFCOOKIES: process.env.VUE_APP_HREFCOOKIES,
      }
      },
    methods: {
      accettoCookies() {
        //validità un mese
        this.$cookies.set('displayCookieConsent', 'y', '30d');
        this.classEUCookies="ginger_banner-wrapper d-none";
        location.reload();
      },
      revocoCookies() {
        this.$cookies.remove('displayCookieConsent');
        this.classEUCookies="ginger_banner-wrapper";
        location.reload();
      },
      getCookie(nomecookie) {
        return this.$cookies.get(nomecookie);
      },
    },
    mounted() {
      if(this.$cookies.isKey('displayCookieConsent')){
          if(this.$cookies.get('displayCookieConsent')=='y'){
            this.classEUCookies="ginger_banner-wrapper d-none";
          }
          else{
            this.classEUCookies="ginger_banner-wrapper";
          }
      }
      else{
        this.classEUCookies="ginger_banner-wrapper";
      }
      
    },
  }

</script>


<style scoped>
/*
  .Cookie {
    background-color: rgb(168, 87, 43);
    color: white;
  }

  .hrefcookies {
    color: white;
    text-decoration: underline;
  }
*/
.ginger-banner *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.ginger-banner p{
    margin-bottom: 1em;
}

.ginger-banner{
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1em;
    color: #232323;
    font-size: 1em;
    z-index: 99999998 !important;
}

.ginger-banner.top{
     top: 0;
     border-bottom: 1px solid #000;
}
 .ginger-banner.bottom{
     bottom: 0;
     border-top: 1px solid #000;
 }
 .ginger-banner.dialog{
     width: 300px;
     border: 1px solid #000;
     box-shadow:1px 1px 2px #000;
     -moz-box-shadow:1px 1px 2px #000;
     -webkit-box-shadow:1px 1px 2px #000;
     right: 10px;
     left: initial;
 }
 .ginger-banner.dialog.top{
     top: 10px;
 }
 .ginger-banner.dialog.bottom{
     bottom: 10px;
 }
 .ginger-banner div.ginger-button-wrapper{
     width: 100%;
     float: left;
 }
 .ginger-banner .ginger-button-wrapper div.ginger-button{
     margin-left: auto;
     margin-right: auto;
     width: 40%;
 }
.ginger-accept, .ginger-dismiss, .ginger-disable{
    width: 160px;
    float: left;
    text-align: center;
    margin-top: 8px !important;
    margin-bottom: 6px !important;
    display: block;
    padding: 8px 16px !important;
    background-color: #f1d600;
    color: #111;
    margin-right: 12px !important;
    text-decoration: none !important;
    font-size:12pt !important;
    font-weight: 600;
    letter-spacing: -0.05em;
    transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
    -moz-transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, -moz-box-shadow 0.2s ease-in-out 0s;
    -webkit-transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, -webkit-box-shadow 0.2s ease-in-out 0s;
}

.ginger-disable{
    background-color: #aaa;
}

.ginger-accept:hover,
.ginger-dismiss:hover,
.ginger-disable:hover{
    margin-top: 8px !important;
    margin-bottom: 6px !important;
    display: block;
    padding: 8px 16px !important;
    background-color: #f1d600 !important;
    color: #111 !important;
    margin-right: 12px !important;
    text-decoration: none !important;
    font-size:12pt !important;
    font-weight: 600;
    letter-spacing: -0.05em;

    box-shadow:1px 1px 2px #000;
    -moz-box-shadow:1px 1px 2px #000;
    -webkit-box-shadow:1px 1px 2px #000;

    transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, box-shadow 0.2s ease-in-out 0s;
    -moz-transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, -moz-box-shadow 0.2s ease-in-out 0s;
    -webkit-transition:background 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s, -webkit-box-shadow 0.2s ease-in-out 0s;
}

.ginger-dismiss{
    position: absolute;
    font-size: .8em;
    right: 1em;
    top: 1em;
    background-color: #ccc;
    color: #333;
}

.ginger-iframe-placeholder{
    padding: 1em;
    background-color: #eee;
    margin: 1em 0;
    text-align: center;
}
 .ginger-iframe-placeholder a.ginger-accept{
     float: none;
     margin-left: auto;
     margin-right: auto !important;
 }

.ginger-iframe-placeholder p{
    margin: 0;
}

.ginger-banner iframe{
    width: 100%;
}



/* Dark theme */

.ginger-banner.dark{
    background: #000000;
    color: white;
}
 .ginger-banner.dark a{
     color: #d7d7d7;
     text-decoration: underline;
 }
 .ginger-banner.light{
     background: white;
     color: black;
 }
 .ginger-banner.light a{
     color: #999999;
     text-decoration: underline;
 }

.ginger-banner.dialog{
     border: 1px solid white;
     box-shadow:1px 1px 2px white;
     -moz-box-shadow:1px 1px 2px white;
     -webkit-box-shadow:1px 1px 2px white;
}



 .ginger_banner-wrapper {
     z-index: 99999998;
     position: relative
 }

 .ginger_container .ginger_btn {
     cursor: pointer;
     text-align: center;
     font-size: 0.6em;
     transition: font-size 200ms;
     line-height: 1em
 }

 .ginger_container .ginger_message {
     font-size: 0.6em;
     transition: font-size 200ms;
     margin: 0;
     padding: 0;
     line-height: 1.5em
 }

 .ginger_container .ginger_logo:hover, .ginger_container .ginger_logo:active {
     opacity: 1
 }

 @media screen and (min-width: 500px) {
     .ginger_container .ginger_btn {
         font-size: 0.8em
     }

     .ginger_container .ginger_message {
         font-size: 0.8em
     }
 }

 @media screen and (min-width: 768px) {
     .ginger_container .ginger_btn {
         font-size: 1em
     }

     .ginger_container .ginger_message {
         font-size: 1em;
         line-height: 1em
     }
 }

 @media screen and (min-width: 992px) {
     .ginger_container .ginger_message {
         font-size: 1em
     }
 }

 @media print {
     .ginger_banner-wrapper, .ginger_container {
         display: none
     }
 }

 .ginger_container.bottom {
     position: fixed;
     left: 0;
     right: 0;
     bottom: 0;
     overflow: hidden;
     padding: 10px
 }
 .ginger_container.top {
     position: fixed;
     left: 0;
     right: 0;
    top: 0;
     overflow: hidden;
     padding: 10px
 }

 .ginger_container .ginger_btn {
     padding: 8px 10px;
     background-color: #f1d600;
     cursor: pointer;
     transition: font-size 200ms;
     text-align: center;
     font-size: 0.6em;
     display: block;
     width: 33%;
     margin-left: 10px;
     float: right;
     max-width: 120px
 }

 .ginger_container .ginger_message {
     transition: font-size 200ms;
     font-size: 0.6em;
     display: block
 }

 @media screen and (min-width: 500px) {
     .ginger_container .ginger_btn {
         font-size: 0.8em
     }

     .ginger_container .ginger_message {
         margin-top: 0.5em;
         font-size: 0.8em
     }
 }

 @media screen and (min-width: 768px) {
     .ginger_container {
         padding: 15px 30px 15px
     }

     .ginger_container .ginger_btn {
         font-size: 1em;
         padding: 8px 15px
     }

     .ginger_container .ginger_message {
         font-size: 1em
     }
 }

 @media screen and (min-width: 992px) {
     .ginger_container .ginger_message {
         font-size: 1em
     }
 }

 .ginger_container {
     background: #222;
     color: #fff;
     font-size: 17px;
     box-sizing: border-box
 }
 .ginger_container.dark{
     background: #222;
     color: #fff;
 }
 .ginger_container.light {
             background-color: #ff6600;
             color: #ffffff;
                        }

 .ginger_container ::-moz-selection {
     background: #ff5e99;
     color: #fff;
     text-shadow: none
 }

 .ginger_container .ginger_btn, .ginger_container .ginger_btn:visited {
     color: #000;
     background-color: #f1d600;
     transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
     -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
     border-radius: 5px;
     -webkit-border-radius: 5px
 }

 .ginger_container .ginger_btn:hover, .ginger_container .ginger_btn:active {
     background-color: #fff;
     color: #000
 }

 .ginger_container a, .ginger_container a:visited {
     text-decoration: none;
     color: #31a8f0;
     transition: 200ms color
 }

 .ginger_container a:hover, .ginger_container a:active {
     color: #b2f7ff
 }

 @-webkit-keyframes slideUp {
     0% {
         -webkit-transform: translateY(66px);
         transform: translateY(66px)
     }

     100% {
         -webkit-transform: translateY(0);
         transform: translateY(0)
     }
 }

 @keyframes slideUp {
     0% {
         -webkit-transform: translateY(66px);
         -ms-transform: translateY(66px);
         transform: translateY(66px)
     }

     100% {
         -webkit-transform: translateY(0);
         -ms-transform: translateY(0);
         transform: translateY(0)
     }
 }

 .ginger_container, .ginger_message, .ginger_btn {
     animation-duration: 0.8s;
     -webkit-animation-duration: 0.8s;
     -moz-animation-duration: 0.8s;
     -o-animation-duration: 0.8s;
     -webkit-animation-name: slideUp;
     animation-name: slideUp
 }

 a.ginger_btn.ginger-accept, a.ginger_btn.ginger-disable, .ginger_btn {
                background: #ffffff !important;
            }

            a.ginger_btn.ginger-accept:hover, a.ginger_btn.ginger-disable:hover, .ginger_btn {
                background: #ffffff !important;
            }

                                    a.ginger_btn {
                color: #ff6600 !important;
            }

                                    .ginger_container.light a {
             color: #ffffff;
                        }

a.ginger_btn.ginger-accept:hover, a.ginger_btn.ginger-disable:hover, .ginger_btn {
    background: #ffffff !important;
}

div{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

</style>
