/* eslint-disable */
<template>
  <!-- Page Content -->
  <div class="container container-interno">

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>  
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Registrazione</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
              Modulo di registrazione
          </h3>
        </div>
      </div>
    </div>

    <vue-element-loading :active="loadingDatiRegistrazione" :is-full-screen="true" 
      background-color="rgba(211,211,211,.6)" text="Caricamento...">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
        </div>
      </div>
    </vue-element-loading>
    <vue-snotify></vue-snotify>

  <!--- Contenuto pagina --->
    <div class="container-pagine-interne">

      <div class="tab-content spazio_sopra spazio_sotto" id="tabs-content">
        <div class="tab-pane fade show active" id="iscriz" role="tabpanel" 
        aria-labelledby="tab_iscrizione">
          <div class="card h-100">
              <!--<h4 class="card-header">Riepilogo dati di registrazione</h4>-->
              <div class="card-body">
                  <vue-form-generator 
                  @validated="onValidated" 
                  ref="vfg" tag="div" :schema="schemaRegistraz" 
                  :model="modalRegistraz" :options="formOptionsRegistraz">
                  </vue-form-generator>
                  <div class="col text-center" v-if="!loadingDatiRegistrazione">
                    <button class="btn btn-lg btn-success" @click="inviaRegistrazione">
                      <i class = "far fa-envelope">&nbsp;&nbsp;<span class = "far">INVIA</span></i>
                    </button>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>
  <!-- /.container -->
  
</template>

<script>
  import Auth from '@/services/api/Auth_User.js'
  import Lookup from '@/services/api/Lookup.js'
  import Registraz from '@/scripts/Registrazione.js'
  import ApiRegistraz from '@/services/api/Registrazione.js'
  
  //vue-form-generator
  import VueFormGenerator from 'vue-form-generator'
  import 'vue-form-generator/dist/vfg.css'
  VueFormGenerator.validators.resources.fieldIsRequired= "Campo obbligatorio";
  VueFormGenerator.validators.resources.invalidEmail= "Indirizzo e-mail non valido";
  //loading
  import VueElementLoading from 'vue-element-loading'
  //css vue-multiselect
  import 'vue-multiselect/dist/vue-multiselect.min.css'
  //notifiche
  import Vue from 'vue'
  //import Snotify from 'vue-snotify'
  import Snotify, { SnotifyPosition } from 'vue-snotify'
  // Import notify styles
  import 'vue-snotify/styles/material.css'
  // Tell Vue about Snotify
  //Vue.use(Snotify);
  const optionsSnotify = {
    toast: {
      position: SnotifyPosition.centerCenter,
      titleMaxLength: 150,
      bodyMaxLength:300,
    }
  }
  //impostazioni globali
  Vue.use(Snotify, optionsSnotify);

  export default {
    name: 'Registrazione',
    props: {
      msg: String
    },
    methods: {
    init(){
      
          //Valorizzazione dinamica select options
          var allcomuni = [];
          var allregioni = [];
          var allprofessioni = [];
          var errorMounted='';
          var titolo='';

          //leggo i valori dei comuni
          Lookup.getAllComuni()
            .then(function (response) {
              //setto l'array che contiene i valori
              //this.allcomuni = response.data;
              allcomuni = response.data;
            })
            .catch(function(error){
              errorMounted=error;
            })
            .finally(() => {
              //leggo i valori delle regioni
              Lookup.getAllRegioni()
                .then(function (response) {
                  //setto l'array che contiene i valori
                  //this.allregioni = response.data;
                  allregioni = response.data;
                })
                .catch(function(error){
                  errorMounted=error;         
                })
                .finally(() => {
                  //leggo i valori delle professioni
                  Lookup.getAllProfessioni()
                    .then(function (response) {
                      //setto l'array che contiene i valori
                      //this.allregioni = response.data;
                      allprofessioni = response.data;
                    })
                    .catch(function(error){
                      errorMounted=error;       
                    })
                    .finally(() => {
                      //console.log(allcomuni);
                      //console.log(allregioni);
                      //console.log(allprofessioni);
                      this.schemaRegistraz = Registraz.getSchemaRegistraz(allcomuni, allregioni, allprofessioni)
                      this.modalRegistraz={};
                      this.loadingDatiRegistrazione = false
                      this.error=errorMounted;
                      if(this.error!=''){
                        console.error(this.error);
                        titolo='Operazione fallita';
                        this.$snotify.error(
                          this.error,
                          titolo,
                          {
                            position: SnotifyPosition.centerCenter,
                            titleMaxLength: 150,
                            bodyMaxLength:300,
                            showProgressBar: false,
                            timeout:0
                          }
                        );
                      }
                    });
                });
            });
      
    },
    onValidated(isValid /*, errors*/) {
      //console.log('isValid',isValid);
      this.isValid=isValid;
    },
    inviaRegistrazione: function() {
        var errorInviaReg='';
        //console.log('this.isValid',this.isValid);
        if(this.isValid){
          this.loadingDatiRegistrazione = true;
          var corpo='';
          var titolo='';
          var codicefiscale=Registraz.getCodiceFiscale(this.modalRegistraz).toUpperCase();
          var postdata = {};
          postdata['codfis'] = codicefiscale;
          postdata['grecaptcharesp'] = encodeURIComponent(grecaptcha.getResponse());
          //console.log('codicefiscale',codicefiscale);
          //console.log('postdata',postdata); 
        var errorIsUsedInserted='';
        ApiRegistraz.isUserInserted(postdata)
        .then(ret => {
              var ritorno=ret;
              if(ritorno=="True"){
                  titolo='Operazione fallita';
                  corpo="Il codice fiscale inserito "+codicefiscale+" è già presente a sistema, selezionare un nuovo codice fiscale.";
                  errorIsUsedInserted=corpo;
              }
              else{
                  if (ritorno.split('|')[1] == 'RECAPTCHA') {
                    titolo='Operazione fallita';
                    corpo = "Registrazione non completata.\n";
                    corpo += "Eseguire il test del reCAPTCHA";
                    errorIsUsedInserted=corpo;
                  } else {}
              }
              
        })
        .catch(function(error){
          errorIsUsedInserted=error;
        })
        .finally(() => {
          this.loadingDatiRegistrazione = false;
          this.error=errorIsUsedInserted;
          if(this.error!=''){
            console.error(this.error);
            titolo='Operazione fallita';
            this.$snotify.error(
              this.error,
              titolo,
              {
                position: SnotifyPosition.centerCenter,
                titleMaxLength: 150,
                bodyMaxLength:300,
                showProgressBar: false,
                timeout:0
              }
            );
          }
          else{
            this.loadingDatiRegistrazione = true;
            //creo nuovo utente e invio mail di conferma registrazione
            Registraz.creaNuovoUser(this,codicefiscale,SnotifyPosition);
          }
        });
        


        }
        else{
            corpo='Compilare tutti i campi obbligatori nella pagina';
            titolo='Attenzione';
            //this.$snotify.warning(
            this.$snotify.error(  
              corpo,
              titolo,
              {
                position: SnotifyPosition.centerCenter,
                titleMaxLength: 150,
                bodyMaxLength:300,
                showProgressBar: true,
                timeout:3000, //millisecondi
              }
            );

        }
        
      }
    },
    data() {
      return {
        loadingDatiRegistrazione: true,
        geonavUserName: '',
        geonavPsw: '',
        //definizione vue-form-generator
        modalRegistraz: {}, //dati
        schemaRegistraz: {},
        formOptionsRegistraz: Registraz.getFormOptionsRegistraz(), //le opzioni
        isValid: false,
        error:'',
        id_nuovaregistrazione:'',
        banner: {
          src: require('../../public/SCTProfessional/static/images/banner_registrazione.png')
        },
        VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
      }
    },
    components: {
      "vue-form-generator": VueFormGenerator.component, VueElementLoading
    },
    /*serve per capire come cambiare i testi dei vari messaggi 
    di errore di validazione
    created() {
      var res = VueFormGenerator.validators.resources;
      console.log(res);
    },
    */
    mounted() {
      this.init();
    } //mounted
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>
.cursor-pointer{
  cursor: pointer
}
</style>
<style>
.field-wrap, .multiselect, .multiselect__single, .multiselect__input{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.snotifyToast__body{
  font-weight: bold !important;
}
.btn-success,
.btn-success:hover {
  font-weight: 100;
  font-size: 15px;
}

</style>


