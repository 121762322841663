import axios from 'axios'

//API per invio mail di segnalazione

function getUrlSendMailSegnalaz() {
  var url = '/SCTProfessional/default/InviaMailInfo';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url;
  }
  */
  //url = url + '/' + param;
  return url;
}

export default {
  SendMailSegnalaz(postdata) {
    //console.log('postdata', postdata);
    return axios
      .post(getUrlSendMailSegnalaz(), {
        JsonStr: postdata
      })
      .then(response => {
        return response.data;
      })
  },

}
