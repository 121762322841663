<template>
<div>
            <vue-element-loading :active="loading" :is-full-screen="true" 
            background-color="rgba(211,211,211,.6)" text="Caricamento...">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                </div>
            </div>
            </vue-element-loading>

            <swiper v-if="allnews" ref="mySwiper" 
            :options="swiperOption" 
            @slideChange="onSlideChange" 
            @tap="onSlideChange">
            <swiper-slide v-for="news in allnews.slice(0,maxnumnews)"  :key="news.id">
                <div :class="setDivClassNews(news.datanews)" style="border: none;">
                  <h5><i class="fas fa-exclamation-circle"></i>&nbsp;<a :class="setAClassNews(news.datanews)" :href="news.href"> {{ news.titolo }}</a>&nbsp;({{ news.datanews }})</h5>
                </div>
              </swiper-slide>
              <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
              <!--
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
              -->
            </swiper>
</div>
</template>


<script>
import NewsApi from '@/services/api/News.js'
import VueElementLoading from 'vue-element-loading'

//vue-awesome-swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'NewsSwiper',
  props: {
    msg: String
  },
  components:{
      VueElementLoading, swiper, swiperSlide,
    },
  data() {
    return {
      loading: true,
      allnews: [],
      error:'',
      mySwiper:[],
      maxnumnews:9,
      /*swiper*/     
      swiperOption: {
          direction: 'vertical',
          spaceBetween: 30,
          //slidesPerGroup: 3,
          //slidesPerColumn: 3,
          slidesPerView: 3,
          speed:1000,
          mousewheel: true,
          //centeredSlides: true,
          //dynamicBullets: true,
          keyboard: {
            enabled: true,
          },
          loop: false,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false
            //disableOnInteraction: true
          },
          pagination: {
             el: '.swiper-pagination',
            //dynamicBullets: true
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        },
        

    }
  },
  methods: {
    onSlideChange(){
      var curswipe=this.$refs.mySwiper.swiper;
      if(curswipe.isEnd){
        setTimeout(function(){ 
          //curswipe.slideToLoop(0, 1000, false);
        }, 2000);
      }
    },
    setDivClassNews(data){
      var strdata = data.split("/");
      var ddata = new Date(parseInt(strdata[2],10),
      parseInt(strdata[1],10)-1,
      parseInt(strdata[0],10),0,0,0);
      var inf = new Date();
      inf.setHours(0,0,0,0);
      inf.setDate(inf.getDate() - 15);
      var sup = new Date();
      sup.setHours(0,0,0,0);
      sup.setDate(sup.getDate() + 15);
      if(inf<ddata && ddata<sup){return "container bg-green col card card-custom";}
      return "container bg-grey col card card-custom";
      //if(inf<ddata && ddata<sup){return "card text-center bg-green";}
      //return "card text-center bg-grey";
      //if(inf<ddata && ddata<sup){return "container bg-green";}
      //return "container bg-grey";
    },
    setAClassNews(data){
      var strdata = data.split("/");
      var ddata = new Date(parseInt(strdata[2],10),
      parseInt(strdata[1],10)-1,
      parseInt(strdata[0],10),0,0,0);
      var inf = new Date();
      inf.setHours(0,0,0,0);
      inf.setDate(inf.getDate() - 15);
      var sup = new Date();
      sup.setHours(0,0,0,0);
      sup.setDate(sup.getDate() + 15);
      if(inf<ddata && ddata<sup){return "bg-green";}
      return "bg-grey";
    },

  },
  mounted(){
    // current swiper instance
    //console.log('this is current swiper instance object', this.swiper)
    this.$refs.mySwiper.swiper.autoplay.stop();
    var errorMounted='';
    NewsApi.getAllNews()
      .then(allnews => {this.allnews = allnews})
      .catch(function(error){
          errorMounted=error;    
        })
      .finally(() => {
        this.loading=false
        this.error=errorMounted;
        if(this.error!=''){
          console.error(this.error);
        }
      })
      //attivo l'autoplay solo quando la pagina è caricata
      this.$refs.mySwiper.swiper.autoplay.start();
      this.mySwiper=this.$refs.mySwiper.swiper;
      //this.mySwiper.slideToLoop(0, 1000, false);
  },
}
</script>


<style>
.swiper-container{
  /*height: 60px;*/
  height: 150px;
  text-align: center;
}
.bg-grey{
color:grey;
}
.bg-green{
color:green;
}
.swiper-button-next{
  background-image: url('data:image/svg+xml,%3Csvg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" class="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"%3E%3Cpath fill="%23408D3F" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"%3E%3C/path%3E%3C/svg%3E');
}
.swiper-button-prev{
  background-image: url('data:image/svg+xml,%3Csvg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"%3E%3Cpath fill="%23408D3F" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"%3E%3C/path%3E%3C/svg%3E');
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background:#408D3F !important;
}


</style>

