
<template>
    <div>
      <!-- Footer-->
      <footer class="py-5-0">
        <div class="container">

          <!--- Footer: Riga grigia --->
            <div class="table-responsive table-responsive-footer">
              <table class="table-sm sfondo-grigio">
              <tbody>
                <tr>
                  <td style="width: 100%">
                    <p class="m-0 text-center spazio_sopra"><a :href="VUE_APP_HREFCOOKIES" class="hrefcookies" style="text-decoration:underline">Cookie</a></p>
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%">
                    <p class="m-0 text-center"><a href="http://www.regione.vda.it/" target="_blank" title="Regione Valle d'Aosta"><img :src="img_logo_regione.src" /></a></p>
                  </td>
                </tr>
                <tr>
                  <td style="width: 100%">
                    <p class="m-0 text-center" style="font-size:13px"><b>
                    Assessorato opere pubbliche, territorio e ambiente<br>
                    Dipartimento programmazione, risorse idriche e territorio<br>
                    Pianificazione Territoriale<br>
                    Ufficio cartografico</b>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!--- Footer: Riga scura --->
        <div class="container-header-footer">
          <div class="row">
            <div class="card-body">
              <p class="text-light text-left " style="font-size:13px"><a href="http://www.invallee.it" target="_blank" title="IN.VA SPA" class="link_verde_chiaro"><b>INVA</b></a> © Copyright - SCT-Outil v<span v-html="vers_sito"></span> - {{anno_cur}}</p>
            </div>
          </div>
          <div class="row">
            <div class="card-body">
              <p class="text-right "><a href="http://geoportale.regione.vda.it/" target="_blank" title="SCT Geoportale"><img :src="img_geoportale.src" /></a></p>
            </div> 
          </div>
        </div>


        <!-- /.container -->
      </footer>

    </div>

</template>

<script>

//<VersioneSito input-name="someName"></VersioneSito>

export default {
  name: 'Footer',
  props: {
    msg: String
  },
  data () {
      return {
        img_geoportale:{src:require('../../public/SCTProfessional/static/images/logo_sct_piccolo.png')},
        img_logo_regione:{src: require('../../public/SCTProfessional/static/images/logo_regione_centrato.png')},
        vers_sito:process.env.VUE_APP_VERS_SITO,
        anno_cur:this.getCurYear(),
        VUE_APP_HREFCOOKIES: process.env.VUE_APP_HREFCOOKIES,
      }
    },
    methods: {
      getCurYear(){
        return new Date().getFullYear();
      }
    },  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
-->
