var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container-interno"},[_c('DialogIframe',{ref:"dialogiframe"}),_c('div',{staticClass:"banner"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.banner.src}})]),_c('div',[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{staticClass:"hrefhome",attrs:{"href":_vm.VUE_APP_HREFHOME}},[_vm._v("Home")])]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Repertori cartografici disponibili")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card h-100"},[_c('h3',{staticClass:"card-header card-titolo"},[_vm._v(" Repertori cartografici disponibili "),_c('span',{staticClass:"float-right"},[_c('a',{staticClass:"pdfopendata",attrs:{"href":_vm.VUE_APP_PDFOPENDATA,"target":"_blank"}},[_c('img',{staticClass:"img-thumbnail",attrs:{"title":"Licenza Creative Commons Attribuzione 4.0 Internazionale (CC BY 4.0)","src":_vm.ccby.src}})])])])])])]),_c('div',{staticClass:"container-pagine-interne spazio_sopra spazio_sotto"},[_c('h5',{staticClass:"card-header"},[_vm._v("Disponibilità complessiva")]),_c('vue-element-loading',{attrs:{"active":_vm.loadingAllRepertori,"is-full-screen":true,"background-color":"rgba(211,211,211,.6)","text":"Caricamento..."}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}})])]),_c('vue-good-table',{attrs:{"columns":_vm.columnsTableRepertAllRepertori,"rows":_vm.rowsTableRepertAllRepertori,"search-options":{
        enabled: true,
        placeholder: 'Cerca in tutti i repertori disponibili',
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        position: 'bottom',
        perPage: 5,
        perPageDropdown: [5, 10, 20, 40, 80, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: 'Righe per pagina',
        ofLabel: 'di',
        pageLabel: 'Pagina',
        allLabel: '[Tutte]',
      }},on:{"on-cell-click":_vm.onClickCellTableRepertDispComplessiva}},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" Nessun risultato ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }