<template>
  <!-- Page Content -->
  <div class="container container-interno">

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Informazioni</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
            Informazioni
          </h3>
        </div>
      </div>
    </div>


    <div class="container-pagine-interne">
      <div class="row spazio_sopra spazio_sotto">
        <div class="spazio_destra_sinistra">

          <!--- Bottone Demo --->
          <div class="col-lg-5 mb-6 allinea-destra">
            <div class="card h-100">
              <div class="card-body">
                <p class="card-text text-center">
                  <img class="img-thumbnail-bianco imgdemo sfondo-bianco" :src="bottone_demo.src">
                  &ensp;&ensp;<a class="hrefdemo" :href="VUE_APP_HREFDEMO">Webinar SCT-Outil</a>
                </p>
              </div>
            </div>
          </div>

          <p>
            Il servizio <b>SCT-Outil</b> è un servizio web rivolto ad un'utenza tecnica costituito da una serie di
            funzionalità specialistiche
            per la visualizzazione, interrogazione e trattamento dei dati territoriali e dei contenuti cartografici
            presenti in
            SCT (Sistema delle Conoscenze Territoriali) della Regione Autonoma Valle d'Aosta.
          </p>

          <p>
            <b>SCT-Outil</b> si compone in due moduli specializzati:
          </p>

          <div class="container">
            <div class="row">
              <div class="col-3">
                <img :src="img_sctoutil_libre.src" class="img-fluid">
              </div>
              <div class="col-9 my-auto">
                modulo gratuito e riservato agli utenti registrati.
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <img :src="img_sctoutil_pro.src" class="img-fluid">
              </div>
              <div class="col-9 my-auto">
                modulo in abbonamento al <b>costo di 70 euro/anno con una validità di 365 giorni dalla
                  sottoscrizione</b>
                come definito dalla <b>D.G.R n°51 del 22 gennaio 2018</b>.
              </div>
            </div>
          </div>

          <br>

          <p v-if="tipoutilizzo == 'noauth'">
            Per le modalità di adesione si rimanda all'apposita sezione
            <a :href="VUE_APP_HREFADESIONE" class="urlmail hrefadesione_noauth">adesione <i
                class="fas fa-external-link-alt"></i></a> del
            portale.
          </p>
          <p v-else>
            Per le modalità di adesione si rimanda all'apposita sezione
            <a :href="VUE_APP_HREFADESIONE" class="urlmail hrefadesione_auth">adesione <i
                class="fas fa-external-link-alt"></i></a> del
            portale.
          </p>

          <p>
            Per l'accesso ai servizi l'utente dovrà disporre delle credenziali della Tessera Sanitaria/Carta Nazionale
            dei Servizi (TS/CNS).
            Si ricorda, qualora non si disponga di un'utenza attiva, che è necessario recarsi in uno dei punti di
            attivazione presenti in
            tutta la Valle d'Aosta (Azienda USL, Sportelli sociali, Biblioteche, Comuni, Unités des Communes
            valdôtaines) con
            la Tessera Sanitaria, la Carta d'Identità, un indirizzo email e ritirare le credenziali di accesso. Per
            ulteriori informazioni
            si rimanda alla <a href="http://www.regione.vda.it/TesseraSanitaria_CartaNazionaledeiServizi/default_i.asp"
              target="_blank" class="urlmail">sezione dedicata <i class="fas fa-external-link-alt"></i></a>
            del sito istituzionale della Regione Autonoma Valle d'Aosta.
          </p>

          <p>Il servizio <b>SCT-Outil</b> è articolato nei seguenti moduli:</p>

          <!--- Contenuto pagina --->
          <div class="container-pagine-interne">

            <div class="row spazio_sotto spazio_destra_sinistra">
              <div class="col-lg-12 mb-12">
                <div class="card h-100">
                  <h5 class="card-header"><img :src="img_sctoutil_libre.src" class="img-fluid"></h5>
                  <div class="card-body">
                    <p class="card-text">
                      <b>Repertorio Cartografico Gratuito</b>
                    </p>
                    <p class="card-text">
                      <b>Sistema di download dei dati cartografici pubblici di SCT</b> che
                      permette all'utente di scaricare localmente l’ultima versione delle informazioni territoriali
                      pubbliche
                      di
                      interesse e
                      di mantenere
                      sotto controllo l'eventuale aggiornamento/ nuova disponibilità di tali informazioni. Oltre al
                      dato
                      cartografico in formato shape,
                      sono rese disponibili per l'utilizzo con Quantum Gis le grafie che rendono la rappresentazione
                      dei dati
                      identica a quella
                      pubblicata attraverso i GeoNavigatori Cartografici di SCT. Per leggere il territorio in modo
                      univoco, a
                      prescindere dallo
                      strumento in uso.
                    </p>

                    <p class="card-text">
                      I dati cartografici a download disponibili tramite il servizio "Repertorio cartografico
                      Gratuito" di SCT
                      Outil
                      vengono ceduti con <a href="https://creativecommons.org/licenses/by/4.0/deed.it" target="_blank"
                        class="urlmail">Licenza
                        Creative Commons Attribuzione 4.0 Internazionale (CC BY 4.0)</a>&nbsp;<a
                        :href="VUE_APP_PDFOPENDATA" target="_blank" class="urlmail pdfopendata"><img :src="ccby.src"
                          alt="" class="img-thumbnail"></a> nel sistema di riferimento UTM-ED50
                      (UTM fuso 32 Nord - Datum geodetico ED50).
                    </p>
                    <p class="card-text">
                      La pagina <a :href="VUE_APP_HREFALLREPERTORI" class="urlmail hrefallrepertori">Repertori
                        cartografici
                        disponibili <i class="fas fa-external-link-alt"></i></a>
                      visualizza l’elenco aggiornato di tutte le cartografie disponibili per il download.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row spazio_destra_sinistra">
              <div class="col-lg-12 mb-12">
                <div class="card h-100">
                  <h5 class="card-header"><img :src="img_sctoutil_pro.src" class="img-fluid"></h5>
                  <div class="card-body">
                    <p class="card-text"><b>GeoNavigatore</b></p>
                    <p class="card-text">
                    <ul>
                      <li>
                        <b>GeoNavigatore SCT specializzato per un uso desktop che fornisce funzionalità
                          specialistiche
                          nell'accesso ai dati territoriali.</b>
                        <br><br>
                        Al GeoNavigatore SCT web standard sono aggiunte alcune funzionalità pensate in modo specifico
                        per l'utente professionista, come la ricerca e/o selezione di particella catastale, uno
                        strumento di disegno avanzato,
                        uno strumento di interrogazione avanzato, un modulo esportazione di mappe georiferite, una
                        funzione di gestione delle viste
                        e uno strumento di generazione di profili altimetrici.
                        <br><br>
                      </li>
                      <li>
                        <b>WEB App cartografica</b>
                        <br><br>
                        WEB App cartografica con funzionalità orientate all'uso in mobilità (smartphone e tablet).
                      </li>
                    </ul>
                    </p>

                    <p class="card-text"><b>Catasto</b></p>

                    <p class="card-text">
                      Sistema di conversione dei file catastali dal formato CMF forniti dall'Agenzia delle Entrate
                      verso il
                      formato SHP
                      conforme alle strutture dati e al sistema di coordinate di SCT. I dati vengono restituiti in
                      una
                      modalità
                      compatibile
                      e quindi altamente integrabile con il resto del mondo cartografico SCT.
                    </p>

                    <p class="card-text"><b>Strumenti</b></p>

                    <p class="card-text">
                      Sezione specifica in cui sono disponibili vari strumenti specializzati realizzati per favorire
                      le
                      attività
                      lavorative cartografiche dei professionisti in ambito Gis Desktop:
                    </p>

                    <p class="card-text">
                    <ul>
                      <li>
                        <p><b>PlugIn x Quantum GIS:</b> Alcune utility presenti sui GeoNavigatori Cartografici di
                          SCT sono
                          rese
                          disponibili anche nel
                          mondo desktop Open Source di Quantum Gis come la ricerca di località, il geocalcolatore,
                          i
                          pittometri,
                          ecc.
                          Per rendere l’uso di Qgis fortemente simile e integrato con il mondo SCT.</p>
                      </li>
                    </ul>
                    </p>


                    <p class="card-text">
                      <b>Repertorio Cartografico completo</b>
                    </p>
                    <p class="card-text">
                      <b>Sistema completo di download dei dati cartografici di SCT</b> che
                      permette all'utente di scaricare localmente l’ultima versione delle informazioni territoriali
                      complete
                      di
                      interesse e
                      di mantenere
                      sotto controllo l'eventuale aggiornamento/ nuova disponibilità di tali informazioni. Oltre al
                      dato
                      cartografico in formato shape,
                      sono rese disponibili per l'utilizzo con Quantum Gis le grafie che rendono la rappresentazione
                      dei dati
                      identica a quella
                      pubblicata attraverso i GeoNavigatori Cartografici di SCT. Per leggere il territorio in modo
                      univoco, a
                      prescindere dallo
                      strumento in uso.
                    </p>

                    <p class="card-text">
                      I dati cartografici a download disponibili tramite il servizio "Repertorio cartografico
                      Completo" di SCT
                      Outil
                      vengono ceduti con <a href="https://creativecommons.org/licenses/by/4.0/deed.it" target="_blank"
                        class="urlmail">Licenza
                        Creative Commons Attribuzione 4.0 Internazionale (CC BY 4.0)</a>&nbsp;<a
                        :href="VUE_APP_PDFOPENDATA" target="_blank" class="urlmail pdfopendata"><img :src="ccby.src"
                          alt="" class="img-thumbnail"></a> nel sistema di riferimento UTM-ED50 (UTM fuso 32 Nord -
                      Datum geodetico ED50).
                    </p>
                    <p class="card-text">
                      La pagina <a :href="VUE_APP_HREFALLREPERTORI" class="urlmail hrefallrepertori">Repertori
                        cartografici
                        disponibili <i class="fas fa-external-link-alt"></i></a>
                      visualizza l’elenco aggiornato di tutte le cartografie disponibili per il download.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
  <!-- /.container -->
</template>

<script>
export default {
  name: 'Informazioni',
  props: {
    msg: String,
    tipoutilizzo: '', //auth oppure noauth
  },
  mounted() {
    this.tipoutilizzo = this.$props.tipoutilizzo;
  },
  data() {
    return {
      banner: {
        src: require('../../public/SCTProfessional/static/images/banner_info.jpg')
      },
      bottone_demo: {
        src: require('../../public/SCTProfessional/static/images/play_demo.png')
      },
      ccby: {
        src: require('../../public/SCTProfessional/static/images/CC-BY-120.png')
      },
      VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
      VUE_APP_HREFDEMO: process.env.VUE_APP_HREFDEMO,
      VUE_APP_HREFADESIONE: process.env.VUE_APP_HREFADESIONE,
      VUE_APP_PDFOPENDATA: process.env.VUE_APP_PDFOPENDATA,
      VUE_APP_HREFALLREPERTORI: process.env.VUE_APP_HREFALLREPERTORI,
      img_sctoutil_libre: {
        src: require('@/images/Logo_SCT_Outil_Libre_small.png')
      },
      img_sctoutil_pro: {
        src: require('@/images/Logo_SCT_Outil_Pro_small.png')
      },
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>

</style>
