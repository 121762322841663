//funzioni per auth_user e per i dati dell'utente

import axios from 'axios'
/*
let port = ''
if (window.location.origin.indexOf('8080')) {
  port = ':8000'
}

const web2pyhostname = function () {
  const hostname = 'http://' + window.location.hostname + port
  return hostname
}
*/

//API per auth
function getUrlAuth() {
  var url = '/SCTProfessional/default/call/json/get_auth_user_table';
  return url;
}

function getUrlGeoNav() {
  var url = '/SCTProfessional/utente/callauth/json/get_dati_geonav';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/utente/call/json/get_dati_geonav';
    url = 'http://localhost:8000' + url + '_svi';
  }*/
  return url;
}

function getUrlUpdateUser() {
  var url = '/SCTProfessional/utente/update_auth_user';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url + '_svi';
  }*/
  //url = url + '?JsonStr=' + param;
  return url;
}


export default {
  //restituisce lo username (cf) dell'utente corrente
  getUsername() {
    return axios
      .get(getUrlAuth())
      .then(response => {
        return response.data.username;
      })
  },
  getIdUsername() {
    return axios
      .get(getUrlAuth())
      .then(response => {
        return response.data.id;
      })
  },
  //restituisce tutti i dati dell'utente corrente (tranne la psw che è superflua)
  getAuthUserData() {
    return axios
      .get(getUrlAuth())
      .then(response => {
        return response.data;
      })
  },
  //restituisce i dati del geonavigatore
  getDatiGeoNav() {
    return axios
      .get(getUrlGeoNav())
      .then(response => {
        return response.data;
      })
  },
  //aggiorna dati utente
  UpdateUser(data) {
    return axios
      .post(getUrlUpdateUser(), {
        JsonStr: data
      })
      .then(response => {
        return response.data;
      })
  },
}
