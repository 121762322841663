import axios from 'axios'

//API per file upload

function getUrlFileDelete(param) {
  var url = '/SCTProfessional/default/delete_file';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url;
  }
  */
  url = url + '/' + param;
  return url;
}

export default {
  getUrlFileUpload(param) {
    var url = '/SCTProfessional/default/upload_file';
    /*
    if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:8000' + url;
    }
    */
    console.log('param',param);
    url = url + '?param=' + param;
    return url;
  },
  DeleteFile(param, data) {
    return axios
      .post(getUrlFileDelete(param), data)
      .then(response => {
        return response.data;
      })
  },
}
