/*Scripts per componente Registrazione*/ 

//import axios from 'axios'
import VueFormGenerator from 'vue-form-generator'
import Common from '@/scripts/Common.js'
import ApiRegistraz from '@/services/api/Registrazione.js'
//recaptcha
import Vue from "vue";
import fieldRecaptcha from '@/components/VueRecaptcha.vue'
Vue.component("fieldRecaptcha", fieldRecaptcha);
Vue.use(VueFormGenerator);

function get_label_privacy_testo(){
var testo = '';
testo += "<center><b>INFORMATIVA AI SENSI DELL'ART. 13 DEL REGOLAMENTO UE 2016/679</b></center><br>"
testo += "Ai sensi dell'art. 13 del Regolamento UE 2016/679 (Regolamento generale sulla protezione dei dati), si "
testo += "forniscono le seguenti informazioni sul trattamento dei dati personali effettuati dallo stesso.<br><br>"
testo += "<b>TITOLARE DEL TRATTAMENTO DEI DATI E DATI DI CONTATTO</b><br>"
testo += "Il titolare del trattamento dei dati è la Regione Autonoma Valle d'Aosta/Vallée d'Aoste, in persona del "
testo += "legale rappresentante pro tempore, con sede in Piazza Deffeyes, 1 – Aosta, contattabile all'indirizzo pec: "
testo += "<a class='urlmail' href='mailto:segretario_generale@pec.regione.vda.it'>segretario_generale@pec.regione.vda.it</a><br><br>"
testo += "<b>DATI DI CONTATTO DEL RESPONSABILE DELLA PROTEZIONE DEI DATI</b><br>"
testo += "Il responsabile della protezione dei dati (DPO) della Regione Autonoma Valle d'Aosta/Vallée d'Aoste, "
testo += "incaricato di garantire il rispetto delle norme per la tutela della privacy, è raggiungibile ai seguenti indirizzi PEC: "
testo += "<a class='urlmail' href='mailto:privacy@pec.regione.vda.it'>privacy@pec.regione.vda.it</a>"
testo += "(per i titolari di una casella di posta elettronica certificata) o PEI: "
testo += "<a class='urlmail' href='mailto:privacy@regione.vda.it'>privacy@regione.vda.it</a>."
testo += "con una comunicazione avente la seguente intestazione “all'attenzione del DPO della "
testo += "Regione Autonoma Valle d'Aosta/Vallée d'Aoste”.<br><br>"
testo += "<b>FINALITÀ DEL TRATTAMENTO</b><br>"
testo += "I dati forniti sono trattati per garantire l'accesso ai servizi cartografici, ai sensi di quanto previsto"
testo += "dalla legge regionale 25 maggio 2000, n. 11 e dalla deliberazione della Giunta regionale 51/2018.<br><br>"
testo += "<b>DESTINATARI DEI DATI</b><br>"
testo += "I dati sono trattati dal personale della Struttura pianificazione territoriale, Dipartimento programmazione, "
testo += "risorse idriche e territorio, Assessorato opere pubbliche, territorio e ambiente della "
testo += "Regione Autonoma Valle d'Aosta.<br>"
testo += "È, inoltre, destinataria dei dati raccolti per la finalità del trattamento la Società IN.VA. S.p.A designata "
testo += "ai sensi dell'articolo 28 del Regolamento quale responsabile del trattamento.<br><br>"
testo += "<b>PERIODO DI CONSERVAZIONE DEI DATI</b><br>"
testo += "I dati saranno conservati per il tempo strettamente necessario al perseguimento della finalità del trattamento.<br><br>"
testo += "<b>DIRITTI DELL'INTERESSATO</b><br>"
testo += "L'interessato potrà in ogni tempo esercitare i diritti di cui agli artt. 15 e ss. del Regolamento. In "
testo += "particolare potrà richiedere la rettifica o la cancellazione dei dati personali o la limitazione del "
testo += "trattamento dei dati o opporsi al trattamento nei casi ivi previsti, inviando l'istanza al DPO della Regione autonoma "
testo += "Valle d'Aosta/Vallée d'Aoste, raggiungibile agli indirizzi indicati nella presente informativa. L'interessato, se "
testo += "ritiene che il trattamento dei dati personali sia avvenuto in violazione di quanto previsto sal Regolamento UE "
testo += "2016/679, ha diritto di proporre reclamo al Garante per la protezione dei dati personali, si sensi dell'art."
testo += "77 del Regolamento, utilizzando gli estremi di contatto reperibili sul sito "
testo += "<a class='urlmail' target='_blank' href='http://www.garanteprivacy.it'>www.garanteprivacy.it</a>."
return testo;
}

/*funzioni di validazione*/ 
function selectNonVuota(value,field,model) {
    if (value == undefined) {
      return ["Campo obbligatorio"];
    }
    if (value.length>0) {
      return []
    } else {
      return ["Campo obbligatorio"];
    }
}

function isCodiceFiscale(value, field, model) {
  if (value == undefined) {
    return ["Campo obbligatorio"];
  }
  if (value.length > 0) {
    var cf = value.toUpperCase();
    if (cf != '') {
      if (!Common.isCodiceFiscale(cf)) {
        return ["Codice Fiscale inserito in forma errata"];
      }
    }
    return []
  } else {
    return ["Campo obbligatorio"];
  }
}
function isPrivacyLetta(value, field, model) {
  if (value == undefined || !value) {
    return ["Prendere visione dell'informativa sulla privacy"];
  }
  return []
}

export default {
  //definizione colonne per vue-form-generator per il form del modulo di registrazione
  getSchemaRegistraz(allcomuni, allregioni, allprofessioni) {
    //restituisco la struttura dello schema
    return {
      fields: [
        {
          type: 'input',
          inputType: 'text',
          label: 'Cognome',
          model: 'last_name',
          id: "txtlast_name",
          placeholder: 'Cognome',
          featured: true,
          required: true,
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "50"
            }
          },
          validator: ["required"]
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Nome',
          model: 'first_name',
          id: "txtfirst_name",
          placeholder: 'Nome',
          featured: true,
          required: true,
          styleClasses: 'col-md-6',
          validator: ["required"],
          attributes: {
            input: {
              "maxlength": "50"
            }
          }
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Codice fiscale',
          model: 'username',
          id: "txtcodfis",
          //placeholder: 'Codice fiscale',
          featured: true,
          required: true,
          validator: isCodiceFiscale,
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "16",
              "style": 'text-transform: uppercase',
            },
          },
          //validator: ["required"]
        },
        {
          type: 'input',
          inputType: 'email',
          label: 'E-Mail',
          model: 'email',
          id: "txtemail",
          placeholder: 'Indirizzo e-mail',
          featured: true,
          required: true,
          validator: ["email"],
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "40"
            }
          }
        },
        {
          /*integrazione vue-tel-input-*/
          //type: "tel-input",
          type: "input",
          label: "Contatto telefonico",
          name: 'txttelefono',
          model: "telefono",
          required: true,
          /*parametri standard*/
          inputType: 'text',
          id: "txttelefono",
          featured: true,
          styleClasses: 'col-md-12',
          //styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "50"
            }
          },
          validator: ["required"]
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Ente/Società',
          id: "txtente",
          model: 'ente',
          featured: true,
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "50"
            }
          }
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Partita IVA',
          model: 'piva',
          id: "txtpiva",
          featured: true,
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "20"
            }
          }
        },
        {
          //type: 'select',
          type: 'vueMultiSelect',
          label: 'Comune',
          model: 'vuecomune',
          values: function () {
            return allcomuni
          },
          placeholder: "Seleziona un comune",
          featured: true,
          max: 1,
          selectOptions: {
            multiple: true,
            clearOnSelect: true,            
            //multiple: false,
            hideSelected: false,
            searchable: true,
            allowEmpty: true,
            showNoResults: true,
            closeOnSelect: true,
            placeholder: "Seleziona l'opzione",
            label: "name",
            key: "id",
            trackBy: "id",
            id: "selectid_comune",
            selectLabel: 'Invio per selezionare',
            deselectLabel: 'Invio per eliminare',
            selectedLabel: 'Comune selezionato',
          },
          styleClasses: 'col-md-6'
        },
        {
          type: 'input',
          inputType: 'text',
          label: 'Indirizzo',
          model: 'indirizzo',
          id: "txtindirizzo",
          featured: true,
          styleClasses: 'col-md-6',
          attributes: {
            input: {
              "maxlength": "100"
            }
          }
        },
        {
          //type: 'select',
          type: 'vueMultiSelect',
          label: 'Regione',
          //model: 'idregione',
          model: 'vueregione',
          //id: "selectid_regione",
          values: function () {
            return allregioni
          },
          placeholder: "Seleziona una regione",
          featured: true,
          max: 1,
          selectOptions: {
              multiple: true,
              clearOnSelect: true,
              hideSelected: false,
              searchable: true,
              allowEmpty: true,
              showNoResults: true,
              closeOnSelect: true,
              placeholder: "Seleziona la Regione",
              label: "name",
              key: "id",
              trackBy: "id",
              id: "selectid_regione",
              selectLabel: 'Invio per selezionare',
              deselectLabel: 'Invio per eliminare',
              selectedLabel: 'Regione selezionata',
            //noneSelectedText: 'Nessuna selezione'
          },
          styleClasses: 'col-md-6'
        },
        {
          //type: 'select',
          type: 'vueMultiSelect',
          label: 'Professione',
          //model: 'idprofessione',
          model: 'vueprofessione',
          id: "selectid_professione",
          values: function () {
            //allprofessioni.unshift({'id':0,'name':'Nessuna professione selezionata'})
            return allprofessioni
          },
          placeholder: "Seleziona una professione",
          featured: true,
          max: 1,
          required: true,
          validator: selectNonVuota,
          selectOptions: {
              multiple: true,
              clearOnSelect: true,
              showNoResults: true,
              hideSelected: false,
              searchable: true,
              allowEmpty: true,
              closeOnSelect: true,
              placeholder: "Seleziona l'opzione",
              label: "name",
              key: "id",
              trackBy: "id",
              id: "selectid_professione",
              selectLabel: 'Invio per selezionare',
              deselectLabel: 'Invio per eliminare',
              selectedLabel: 'Professione selezionata',
              //noneSelectedText: 'Nessuna selezione'
          },
          styleClasses: 'col-md-6'
        },
        {
          //type: 'switch',
          type: 'checkbox',
          type: 'checkbox',
          label: "Privacy",
          model: 'privacy',
          featured: true,
          required: true,
          validator: isPrivacyLetta,
          styleClasses: 'col-md-3 align-middle',
          default: false,
          //textOn: "Visionata",
          //textOff: "Non visionata",
          //valueOn: true,
          //valueOff: false,
          //multi: true,
          //readonly: false,
        },
        {
          type: 'label', 
          label: get_label_privacy_testo(),
          //label: "",
          //value: get_label_privacy_testo(), 
          model: 'privacy_testo',
          //featured: true,
          styleClasses: 'col-md-9',
        },
        {
          type: "recaptcha",
          label: "ReCAPTCHA",
          featured: true,
          model: 'recaptcha',
          styleClasses: 'col-md-12',
        },


      ]
    } 



  },
  getFormOptionsRegistraz() {
    return {
      validateAfterLoad: true,
      validateAfterChanged: true,
      //validateAsync: true,
    }
  },
  preparaJSon_crud(schema,model) {
    var arrjson = {};
    var vuemulti = [];
    var type='';
    for(var i=0;i<schema.fields.length;i++){ 
      type=schema.fields[i].type.toUpperCase();
      if (type == 'INPUT' || type == 'SELECT' || type == 'LABEL' || type == 'CHECKBOX') {
        arrjson[schema.fields[i].model] = model[schema.fields[i].model];
      }
      if (type == 'VUEMULTISELECT') {
        arrjson[schema.fields[i].model] = model[schema.fields[i].model]
      }
    }
    return arrjson;
  },
  getCodiceFiscale(model) {
    return model['username'];
  },
  getEmail(model) {
    return model['email'];
  },
  generaTabContattiErrori(testoerrore) {
    var ret='';
    ret += '<div class="table-responsive">';
    ret += 'Per ricevere <b>supporto da personale specializzato</b> sono disponibili:';
    ret += '<table width="100%">';
    ret += '<tbody>';
    ret += '<tr>';
    ret += '<td width="50%">';
    ret += '<a href="mailto:infoservizi@regione.vda.it">';
    ret += "<b>L'ASSISTENZA TRAMITE POSTA ELETTRONICA </b>";
    ret += '</a>';
    ret += '</td>';
    ret += '<td>';
    ret += '<a href="mailto:infoservizi@regione.vda.it">';
    ret += '<i class="fas fa-external-link-alt fa-4x"></i>';
    ret += '</a>';
    ret += '</td>';
    ret += '</tr>';
    ret += '<tr>';
    ret += '<td>&nbsp;</td>';
    ret += '</tr>';
    ret += '<tr>';
    ret += "<td><b>L'ASSISTENZA TRAMITE NUMERO VERDE</b>";
    ret += '</td>';
    ret += '<td>';
    ret += '<img src="/SCTProfessional/static/images/numeroverde.jpg"> ';
    ret += '</td>';
    ret += '</tr>';
    ret += '<tr><td align="justify">Per entrambi i servizi è necessario fornire le seguenti specifiche:</td></tr>';
    ret += '<tr><td>&nbsp;</td></tr>';
    ret += '<tr><td align="left">&nbsp;&nbsp;<b>• Denominazione del prodotto che ha generato l&#39;anomalia: SCT Outil</b></td></tr>';
    ret += '<tr><td>&nbsp;</td></tr>';
    ret += '<tr><td align="left">&nbsp;&nbsp;<b>• Riferimento errore:&nbsp;' + testoerrore;
    ret += '</b></td></tr>';
    ret += '<tr>';
    ret += '<td>&nbsp;</td>';
    ret += '</tr>';
    ret += '<tr>';
    ret += '<td>&nbsp;</td>';
    ret += '</tr>';
    ret += '</tbody>';
    ret += '</table>';
    ret += '</div>';
   
    return ret;
  },
  inviaMailConfermaRegistrazione(obj,SnotifyPosition) {
    obj.loadingDatiRegistrazione = true;
    var titolo = '';
    var errorMailConfermaRegistraz = '';
    var arrjson = this.preparaJSon_crud(obj.schemaRegistraz, obj.modalRegistraz);
    arrjson['username'] = arrjson['username'].toUpperCase();
    arrjson['id'] = obj.id_nuovaregistrazione;
    obj.id_nuovaregistrazione = '';
    //console.log('arrjson',arrjson);
    ApiRegistraz.sendMailConfermaRegistraz(arrjson)
      .then(ret => {
      })
      .catch(function (error) {
        errorMailConfermaRegistraz = error;
      })
      .finally(() => {
        obj.loadingDatiRegistrazione = false;
        obj.error = errorMailConfermaRegistraz;
        if (obj.error != '') {
          console.error(obj.error);
          titolo = 'Operazione fallita';
          obj.$snotify.error(
            obj.error,
            titolo, {
              position: SnotifyPosition.centerCenter,
              titleMaxLength: 150,
              bodyMaxLength: 300,
              showProgressBar: false,
              timeout: 0
            }
          );
        } else {
          //operazione completata
          obj.init();
          titolo = 'Operazione completata';
          var htmltesto = '<div class="snotifyToast animated snotify-success fadeIn">';
          htmltesto += '<div class="snotifyToast__inner snotify-success">';
          htmltesto += '<div class="snotifyToast__title text-white"><b>' + titolo + '</b></div>';
          htmltesto += '<div class="snotifyToast__body">';
          htmltesto += "Per completare la registrazione è necessario seguire le indicazioni inviate a <b>";
          htmltesto += this.getEmail(obj.modalRegistraz) + "</b>.<br><br>";
          htmltesto += "Nel caso in cui la notifica non sia presente si prega di controllare nella cartella dello spam.";
          htmltesto += '</div>';
          htmltesto += '<div class="snotify-icon snotify-icon--success"></div>';
          htmltesto += '</div>';
          htmltesto += '</div>';
          obj.$snotify.html(htmltesto, {
            position: SnotifyPosition.centerCenter,
            titleMaxLength: 150,
            bodyMaxLength: 300,
            showProgressBar: false,
            timeout: 0,
          });

        }

      })

    

  },
  creaNuovoUser(obj, codfis, SnotifyPosition) {
    var titolo='';
    var errorInsert_auth_user = '';
    var arrjson = this.preparaJSon_crud(obj.schemaRegistraz, obj.modalRegistraz);
    arrjson['username'] = arrjson['username'].toUpperCase();
    //console.log('arrjson',arrjson);
    ApiRegistraz.insert_auth_user(arrjson)
      .then(ret => {
        obj.id_nuovaregistrazione=ret;
        //resetto il controllo recaptcha
        grecaptcha.reset();
      })
      .catch(function (error) {
        errorInsert_auth_user = error;
      })
      .finally(() => {
        obj.loadingDatiRegistrazione = false;
        obj.error = errorInsert_auth_user;
        if (obj.error != '') {
          console.error(obj.error);
          titolo = 'Operazione fallita';
          obj.$snotify.error(
            obj.error,
            titolo, {
              position: SnotifyPosition.centerCenter,
              titleMaxLength: 150,
              bodyMaxLength: 300,
              showProgressBar: false,
              timeout: 0
            }
          );
        } else {
          //invio mail conferma registrazione
          this.inviaMailConfermaRegistrazione(obj, SnotifyPosition);
        }

      })

  },

}

