/* eslint-disable */
<template>
  <!-- Page Content -->
  <div class="container container-interno">

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Adesione</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
            Guida all'adesione
          </h3>
        </div>
      </div>
    </div>

    <!--- Contenuto pagina --->
    <div class="container-pagine-interne">

      <div class="row spazio_sopra spazio_sotto spazio_destra_sinistra">
        <div class="col-lg-12 mb-12">
          <div class="card">
            <h5 class="card-header"><img :src="img_sctoutil_libre.src" class="img-fluid"></h5>
            <div class="card-body">
              <p class="card-text">
                Per aderire al modulo è necessario completare il processo di <b>registrazione</b>.
                La modalità è di seguito descritta.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row spazio_sopra spazio_sotto spazio_destra_sinistra">
        <div class="col-lg-12 mb-12">
          <div class="card h-100">
            <h5 class="card-header"><img :src="img_sctoutil_pro.src" class="img-fluid"></h5>
            <div class="card-body">
              <p class="card-text">
                Per aderire al modulo è necessario prima completare il processo di <b>registrazione</b>
                e successivamente <b>pagare la quota annuale di 70 euro (nuova iscrizione o proroga abbonamento)</b>.
                Le modalità sono di seguito descritte.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row spazio_sopra spazio_sotto spazio_destra_sinistra">
        <div class="col-lg-12 mb-12">
          <div class="card h-100">
            <h5 class="card-header">Registrazione</h5>
            <div class="card-body">
              <p class="card-text">
                <!--- Prerequisiti --->
                <div class="prerequisiti">
                  <p>Prerequisiti:</p>
                  <ul>
                    <li v-if="tipoutilizzo=='noauth'">Verificare che la propria
                      <a target="_blank" :href="VUE_APP_HREFCNS" class="urlmail hrefcns">Tessera Sanitaria/Carta
                        Nazionale dei Servizi
                        <i class="fas fa-external-link-alt"></i></a>
                      sia abilitata oppure verificare di essere in possesso di un
                      <a target="_blank" :href="VUE_APP_HREFCNS" class="urlmail hrefspid">Sistema Pubblico di Identità
                        Digitale (SPID)
                        <i class="fas fa-external-link-alt"></i></a>.
                      In caso di problemi contattare il
                      <a :href="VUE_APP_HREFCONTATTI" class="urlmail hrefcontatti_noauth__">supporto tecnico <i
                          class="fas fa-external-link-alt"></i></a>.
                    </li>
                    <li v-else>Verificare che la propria Tessera Sanitaria/Carta Nazionale dei Servizi sia abilitata.
                      In
                      caso di problemi contattare il
                      <a :href="VUE_APP_HREFCONTATTI" class="urlmail hrefcontatti_auth__">supporto tecnico <i
                          class="fas fa-external-link-alt"></i></a>.
                    </li>
                    <li>Essere in possesso di un'<b>e-mail esistente e non piena</b>, da indicare in fase di
                      registrazione
                      in quanto verrà utilizzata dal sistema per tutte le comunicazioni con l'utente.
                    </li>
                  </ul>
                </div>
                <!--- Fine prerequisiti --->

                <div class="container text-center">
                  <a :href="VUE_APP_HREFREGISTRAZIONE" class="urlmail hrefregistrazione text-center">
                    <i class="fas fa-user-edit fa-2x"></i><br>&ensp;&ensp;Apri modulo di registrazione
                  </a>
                </div>

                <ul>
                  <li>Selezionare il link <a :href="VUE_APP_HREFREGISTRAZIONE" class="urlmail hrefregistrazione">"Apri
                      modulo di registrazione"
                      <i class="fas fa-external-link-alt"></i></a>.</li>
                  <li>Compilare le informazioni richieste nella pagina web.</li>
                  <li>Infine premere il pulsante "Invia".</li>
                  <li>Aprire l'e-mail ricevuta nella casella di posta elettronica specificata in
                    fase di registrazione con oggetto <b>"SCT OUTIL - Registrazione parziale"</b> e seguire le
                    indicazioni
                    presenti nel testo della notifica per <b>confermare la registrazione.</b><br>
                    <b>Tutte le registrazioni non confermate entro 7 giorni verranno cancellate dagli archivi
                      informatici</b>.</li>
                  <li>Dopo aver confermato la propria registrazione, l'utente riceverà un messaggio di
                    posta elettronica con oggetto
                    <b>"SCT OUTIL - Nuova registrazione"</b> che conferma l'avvenuta registrazione negli archivi
                    informatici del servizio
                    e potrà accedere alla sezione personale del sito web <b>"Il mio account"</b> utilizzando le
                    proprie
                    credenziali SPID/TS/CNS, a tutte le pagine pubbliche e al <b>servizio repertorio cartografico,
                      limitatamente alla base dati pubblica</b>.<br>
                    <b>Per poter accedere a tutti i servizi riservati sarà necessario iscriversi, secondo le modalità
                      di seguito descritte</b>.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row spazio_sopra spazio_destra_sinistra">
        <div class="col-lg-12 mb-12">
          <div class="card h-100">
            <h5 class="card-header">Nuova iscrizione o proroga abbonamento</h5>
            <div class="card-body">
              <p class="card-text">
                <div class="prerequisiti">
                  <p>Prerequisiti:</p>
                  <ul>
                    <li v-if="tipoutilizzo=='noauth'">Verificare che la propria
                      <a target="_blank" :href="VUE_APP_HREFCNS" class="urlmail hrefcns">Tessera Sanitaria/Carta
                        Nazionale dei Servizi
                        <i class="fas fa-external-link-alt"></i></a>
                      sia abilitata oppure verificare di essere in possesso di un
                      <a target="_blank" :href="VUE_APP_HREFCNS" class="urlmail hrefspid">Sistema Pubblico di Identità
                        Digitale (SPID)
                        <i class="fas fa-external-link-alt"></i></a>.
                      In caso di problemi contattare il
                      <a :href="VUE_APP_HREFCONTATTI" class="urlmail hrefcontatti_noauth__">supporto tecnico <i
                          class="fas fa-external-link-alt"></i></a>.
                    </li>
                    <li v-else>Verificare che la propria Tessera Sanitaria/Carta Nazionale dei Servizi sia abilitata.
                      In
                      caso di problemi contattare il
                      <a :href="VUE_APP_HREFCONTATTI" class="urlmail hrefcontatti_auth__">supporto tecnico <i
                          class="fas fa-external-link-alt"></i></a>.
                    </li>
                    <li>Avere completato il processo di registrazione.
                    </li>
                  </ul>
                </div>

                <!--- Link Stato dell'abbonamento --->
                <div class="card-body text-center">
                  <p class="card-text text-center">
                    <a :href="VUE_APP_HREFSTATOABBONAMENTO" class="urlmail hrefstatoabbonamento text-center">
                      <i class="far fa-address-card fa-2x"></i><br>&ensp;&ensp;Accedi alla pagina<br>"Stato
                      dell'abbonamento"
                    </a>
                  </p>
                </div>

                <ul>
                  <li>Selezionare la voce <a :href="VUE_APP_HREFSTATOABBONAMENTO"
                      class="urlmail hrefstatoabbonamento">"Stato dell'abbonamento" <i
                        class="fas fa-external-link-alt"></i></a>
                    del menù di navigazione <b>"Il mio account"</b>.</li>
                  <li>Effettuare il pagamento della quota annuale seguendo le indicazioni presenti
                    nella pagina, utilizzando il servizio
                    <a target="_blank" :href="VUE_APP_HREFPAGOPA" class="urlmail hrefpagopa">pagoPA <i
                        class="fas fa-external-link-alt"></i></a>.
                  </li>
                  <li>Dopo aver effettuato il pagamento, l'utente riceverà una notifica elettronica con oggetto
                    <b>"SCT OUTIL - Nuova iscrizione"</b>
                    (oppure <b>"SCT OUTIL - Proroga iscrizione"</b> in caso di proroga) che
                    conferma l'avvenuto pagamento della quota annuale e potrà accedere a tutti i servizi
                    utilizzando le proprie credenziali SPID/TS/CNS.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!-- /.container -->
</template>

<script>
  export default {
    name: 'Adesione',
    props: {
      msg: String,
      tipoutilizzo: '', //auth oppure noauth
    },
    mounted() {
      this.tipoutilizzo = this.$props.tipoutilizzo;
    },
    data() {
      return {
        banner: {
          src: require('../../public/SCTProfessional/static/images/banner_registrazione.png')
        },
        VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
        VUE_APP_HREFCONTATTI: process.env.VUE_APP_HREFCONTATTI,
        VUE_APP_HREFSTATOABBONAMENTO: process.env.VUE_APP_HREFSTATOABBONAMENTO,
        VUE_APP_HREFREGISTRAZIONE: process.env.VUE_APP_HREFREGISTRAZIONE,
        VUE_APP_HREFCNS: process.env.VUE_APP_HREFCNS,
        VUE_APP_HREFPAGOPA: process.env.VUE_APP_HREFPAGOPA,
        img_sctoutil_libre: {
          src: require('@/images/Logo_SCT_Outil_Libre_small.png')
        },
        img_sctoutil_pro: {
          src: require('@/images/Logo_SCT_Outil_Pro_small.png')
        },
      }
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>

</style>
