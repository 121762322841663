import axios from 'axios'

//API per tabelle di lookup
function getUrlRegioni() {
  var url = '/SCTProfessional/default/call/json/get_regioni';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/default/call/json/get_regioni';
    url = 'http://localhost:8000' + url;
  }*/
  return url;
}

function getUrlComuni() {
  var url = '/SCTProfessional/default/call/json/get_comuni';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/default/call/json/get_comuni';
    url = 'http://localhost:8000' + url;
  }
  */
  return url;
}

function getUrlProfessioni() {
  var url = '/SCTProfessional/default/call/json/get_professioni';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/default/call/json/get_professioni';
    url = 'http://localhost:8000' + url;
  }*/
  return url;
}

export default {
  getAllRegioni() {
    return axios
      .get(getUrlRegioni())
      .then(response => {
        return response.data;
      })
  },
  getAllComuni() {
    return axios
      .get(getUrlComuni())
      .then(response => {
        return response.data;
      })
  },
  getAllProfessioni() {
    return axios
      .get(getUrlProfessioni())
      .then(response => {
        return response.data;
      })
  }
}
