<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white fixed-top">

    <!--<div class="container">-->

    <div class="container container-header-footer">
      <a class="navbar-brand linkhome" :href="VUE_APP_LINKHOME">
        <img :src="img_sct_outil.src" :title="HTMLTITLE" class="img-thumbnail">
      </a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
          </li>
          <a class="nav-link hrefprogetto_noauth" :href="VUE_APP_HREFPROGETTO">Informazioni</a>
          <li class="nav-item">
            <a class="nav-link hrefadesione_noauth" :href="VUE_APP_HREFADESIONE">Adesione</a>
          </li>
          <li class="nav-item">
            <a class="nav-link hrefcontatti_noauth" :href="VUE_APP_HREFCONTATTI">Contatti</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Servizi
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
              <a class="dropdown-item hrefcartageo" :href="VUE_APP_HREFCARTAGEO">Geonavigatore</a>
              <a class="dropdown-item hrefmappecat" :href="VUE_APP_HREFMAPPECAT">Servizio Catasto</a>
              <a class="dropdown-item hrefdownloadfiles" :href="VUE_APP_HREFDOWNLOADFILES">Strumenti</a>
              <a class="dropdown-item hrefrepertcarto" :href="VUE_APP_HREFREPERTCARTO">Repertorio Cartografico</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link hreflogin" :href="getUrlLogin">Login&nbsp;<i class="fas fa-sign-in-alt"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <!--</div>-->
  </nav>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      msg: String
    },
    methods: {
      getUrlLogin() {
        return process.env.VUE_APP_HREFLOGIN + '?_next=' + window.location.pathname
      }
    },
    data() {
      return {
        img_sct_outil: {
          src: require('../../public/SCTProfessional/static/images/logo_sct_outil.png')
        },
        HTMLTITLE: process.env.VUE_APP_HTMLTITLE,
        VUE_APP_LINKHOME: process.env.VUE_APP_LINKHOME,
        VUE_APP_HREFPROGETTO: process.env.VUE_APP_HREFPROGETTO,
        VUE_APP_HREFADESIONE: process.env.VUE_APP_HREFADESIONE,
        VUE_APP_HREFCONTATTI: process.env.VUE_APP_HREFCONTATTI,
        VUE_APP_HREFCARTAGEO: process.env.VUE_APP_HREFCARTAGEO,
        VUE_APP_HREFMAPPECAT: process.env.VUE_APP_HREFMAPPECAT,
        VUE_APP_HREFDOWNLOADFILES: process.env.VUE_APP_HREFDOWNLOADFILES,
        VUE_APP_HREFREPERTCARTO: process.env.VUE_APP_HREFREPERTCARTO,
      }
    },
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
-->
