import axios from 'axios'

//API registrazione
function getUrlIsUserInserted() {
  var url = '/SCTProfessional/default/isUserInserted';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url;
  }*/
  return url;
}

function getUrlInsert_auth_user() {
  var url = '/SCTProfessional/default/insert_auth_user';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url;
  }*/
  return url;
}

function getUrlInviaMailConfermaRegistraz() {
  var url = '/SCTProfessional/default/InviaMailConfermaRegistraz';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = 'http://localhost:8000' + url;
  }*/
  return url;
}

export default {
  //controllo se utente esiste già: isUserInserted
  isUserInserted(data) {
    return axios
      .post(getUrlIsUserInserted(), {
        postdata: data
      })
      .then(response => {
        return response.data;
      })
  },
  //inserimento utente
  insert_auth_user(data) {
    return axios
      .post(getUrlInsert_auth_user(), {
        JsonStr: data
      })
      .then(response => {
        return response.data;
      })
  },
  //invio mail conferma registrazione
  sendMailConfermaRegistraz(data) {
    return axios
      .post(getUrlInviaMailConfermaRegistraz(), {
        JsonStr: data
      })
      .then(response => {
        return response.data;
      })
  },

}
