<template>
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white fixed-top">

  <div class="container container-header-footer">
      <a class="navbar-brand linkhome" :href="VUE_APP_LINKHOME">
        <img :src="img_sct_outil.src" :title="HTMLTITLE" class="img-thumbnail">
      </a>
  </div>
</nav>
</template>

<script>

export default {
  name: 'Header',
  props: {
    msg: String
  },
  data() {
    return {
     
      img_sct_outil: {
        src: require('../../public/SCTProfessional/static/images/logo_sct_outil.png')
      },
      
      HTMLTITLE: process.env.VUE_APP_HTMLTITLE,
      VUE_APP_LINKHOME: process.env.VUE_APP_LINKHOME,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
-->
