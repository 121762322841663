import axios from 'axios'

//API comuni
function getUrlWebScraping(param) {
  var url = '/SCTProfessional/default/call/json/web_scraping';
  url = url + '?url=' + param;
  return url;
}

export default {
    webScraping(param) {
    return axios
      .get(getUrlWebScraping(param))
      .then(response => {
        return response.data;
      }) 
    }
}