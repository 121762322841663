<template>
<!-- Page Content -->
<div class="container container-interno">

  <!--- Banner --->
  <div class="banner">
    <img :src="banner.src" class="img-fluid">
  </div>

  <!-- Page Heading/Breadcrumbs -->
  <div>  
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
      </li>
      <li class="breadcrumb-item active">Cookies</li>
    </ol>
  </div>

  <CookieChoice ref="cookiechoice"></CookieChoice>

  <!--- Titolo Pagina --->
  <div class="row">
    <div class="col">
      <div class="card h-100">
        <h3 class="card-header card-titolo">
            Utilizzo dei cookie nei siti Web
        </h3>
      </div>
    </div>
  </div>

  <div class="container-pagine-interne">
    <div class="spazio_destra_sinistra spazio_sopra spazio_sotto">

  <p>La presente informativa descrive &ldquo;che cosa sono i cookie&rdquo; e i tipi di cookie utilizzati nel nostro sito Web.</p>

  <h5>Che cosa sono i cookie?</h5>

  <p>Un cookie &egrave; un semplice file di testo che viene memorizzato sul computer o dispositivo mobile dell&rsquo;utente dal server di un sito web e che pu&ograve; essere letto o recuperato dal server stesso. Esso contiene alcune informazioni e
    permette al sito web di ricordare, ad esempio, le preferenze dell&rsquo;utente. La Direttiva sulle Comunicazioni Elettroniche e il Provvedimento del Garante Privacy dell&rsquo;8 maggio 2014, n. 229, in materia di &ldquo;Individuazione delle
    modalit&agrave; semplificative per l'informativa e l'acquisizione del consenso per l'uso dei cookie&rdquo;, prevedono che l&rsquo;utilizzo dei cookie possa essere ammesso solo con il consenso dell&rsquo;utente.</p>
  
  <h5>Utilizziamo i cookie nel nostro sito Web?</h5>

  <p>S&igrave;. Vengono utilizzati i cookie per migliorare il proprio sito Web e per fornire servizi e funzionalit&agrave; agli utenti. &Egrave; possibile limitare o disattivare l&rsquo;utilizzo dei cookie tramite il proprio browser Web. Tuttavia,
    in questo caso alcune funzionalit&agrave; del sito Web potrebbero diventare inaccessibili.</p>

  <h5>Perch&eacute; usiamo i Cookie</h5>

  <p>I cookie possono essere impiegati per diversi motivi. In alcune parti del nostro sito i cookie sono essenziali e senza di essi non si potrebbe essere in grado, ad esempio, di navigare correttamente nel sito o sfogliare gli elenchi. Siamo sempre
    alla ricerca di modi per migliorare il nostro sito internet e per meglio diffondere la conoscenza dei nostri servizi. Per fare ci&ograve; per noi &egrave; importante capire come si utilizza il nostro sito. I cookie ci aiutano anche a fornire
    un'esperienza migliore e pi&ugrave; ricca: per qualcosa che si rilevi interessante, si potrebbe mettere il "Mi Piace" su Facebook, o twittarlo (i cookie facilitano queste operazioni).</p>

  <hr class="sfumato" />
  <h4>Quali tipi di cookie sono utilizzati nel nostro sito Web?</h4>


  <h5>Cookie tecnici</h5>

  <p>I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della societ&agrave;
    dell'informazione esplicitamente richiesto dall'abbonato o dall'utente a erogare tale servizio" (cfr. art. 122, comma 1, del Codice Privacy).</p>

  <p>Non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in:</p>

  <h5>Cookie di navigazione o di sessione</h5>

  <p>Sono utilizzati al fine di registrare dati utili alla normale navigazione e fruizione del sito web sul computer dell'utente (permettendo, ad esempio, di ricordare la dimensione preferita della pagina in un elenco). Tali cookie possono essere
    poi distinti in ragione della loro persistenza sul computer dell&rsquo;utente: quelli che si cancellano automaticamente alla fine di ogni navigazione, si chiamano cookie di sessione. Se viceversa hanno una vita pi&ugrave; lunga, si parla di
    cookie permanenti.</p>

  <h5>Cookie funzionali</h5>

  <p>Questi cookie consentono al sito Web di ricordare le scelte effettuate dall&rsquo;utente al fine di ottimizzare le funzionalit&agrave;. Ad esempio, i cookie funzionali consentono al sito Web di ricordare le impostazioni specifiche di un utente,
    come la selezione del paese e, se impostato, lo stato di accesso permanente.</p>

    <div class="table-responsive">
      <table class="tabella_bordo_grigio" border="1" align="left">
        <tbody>
          <tr>
            <td title="Nome del cookie" align="left" valign="top">
              <strong>Nome&nbsp;del&nbsp;cookie<br /></strong>
            </td>
            <td title="Scopo del cookie" align="left" valign="top">
              <strong>Scopo del cookie</strong>
            </td>
          </tr>
          <tr>
            <td title="Nome del cookie">session_id_sctprofessional</td>
            <td title="Scopo del cookie">Il cookie &egrave; usato per mantenere le informazioni relative alla stato della navigazione tra una pagina e l'altra. Il cookie viene automaticamente eliminato quando chiudi il browser.</td>
          </tr>
          <tr>
            <td title="Nome del cookie">displayCookieConsent</td>
            <td title="Scopo del cookie">Utilizzato per archiviare l’accettazione dell’utilizzo dei cookie per l’utente che ne ha acconsentito all’utilizzo premendo il pulsante “OK”, nella barra informativa sull’utilizzo dei cookie. Ha la durata di
              1
              anno.</td>
          </tr>
        </tbody>
      </table>
    </div>
  <p />
  <h5>Cookie analitici</h5>

  <p>Questi cookie consentono di raccogliere dati sull&rsquo;utilizzo del sito Web da parte dell&rsquo;utente, compresi gli elementi su cui si fa clic durante la navigazione, al fine di migliorare le prestazioni e il design del sito stesso.
    Potrebbero essere condivisi con i nostri fornitori di strumenti di analisi terzi. Tuttavia, sono utilizzati esclusivamente per le finalit&agrave; correlate al nostro sito Web.</p>

  <h5>Cookie di terze parti</h5>

  <p>Questi cookie sono legati ai servizi forniti da terze parti, come ad esempio il 'Mi piace' e il "Condividi" e monitorano l'efficacia della pubblicit&agrave;. Il soggetto terzo fornisce questi servizi in cambio di informazioni in merito alla
    visita dell&rsquo;utente al nostro sito. Ne discende, anche per i fornitori terzi di cookie, l&rsquo;obbligo di rispettare la normativa in materia. Per tale motivo rimandiamo ai link delle pagine web dei siti delle terze parti, nelle quali
    l&rsquo;utente potr&agrave; trovare i moduli di raccolta del consenso ai cookie e le loro relative informative. Qualora i servizi fossero forniti da contatti indiretti con le terze parti, l&rsquo;utente dovr&agrave; consultare i siti dei
    soggetti che fanno da intermediari tra noi e le stesse terze parti.</p>

  <hr class="sfumato" />
  <h4>Come si disattivano o si rimuovono i cookie?</h4>

  <p>La maggior parte dei browser accettano i cookies automaticamente, ma potete anche scegliere di non accettarli. Non consigliamo questa ultima scelta dal momento che ci&ograve; potrebbe impedire la normale navigazione e di fruire di tutte le
    funzionalit&agrave; del sito. Se non desiderate che il vostro computer riceva e memorizzi cookies, potete modificare le impostazioni di sicurezza del vostro browser (Internet Explorer, Google Chrome, Safari etc.). In ogni caso, si noti che
    determinate parti del nostro Sito possono essere utilizzate nella loro pienezza solo se il vostro browser accetta i cookies; in caso contrario, non sarete in grado per esempio di accedere all'area riservata. Di conseguenza, la vostra scelta di
    rimuovere e non accettare cookies potr&agrave; influenzare negativamente la vostra permanenza sul nostro sito. Se desiderate modificare le impostazioni dei vostri cookies, entrando nelle impostazioni dei vari browser, riportiamo di seguito brevi
    istruzioni su come effettuare questa operazione nei browser pi&ugrave; diffusi.</p>
    <p>
  <ul>
    <li><a title="Internet Explorer - Attivazione e disattivazione dei cookie" href="http://windows.microsoft.com/it-it/windows7/block-enable-or-allow-cookies" target="_blank">Windows Internet Explorer</a></li>
    <li><a title="Google Chrome - Attivazione e disattivazione dei cookie" href="https://support.google.com/accounts/answer/61416?hl=it">Google Chrome</a></li>
    <li><a title="Mozilla Firefox - Attivazione e disattivazione dei cookie" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" target="_blank">Mozilla Firefox</a></li>
    <li><a title="Apple Safari - Attivazione e disattivazione dei cookie" href="https://support.apple.com/it-it/HT201265" target="_blank">Apple Safari</a></li>
    <li><a title="Opera - Attivazione e disattivazione dei cookie" href="http://help.opera.com/Windows/10.00/it/cookies.html" target="_blank">Opera</a></li>
  </ul>
</p>

  <h4>Revoca del consenso</h4>


  <p>Con questa selezione puoi revocare il consenso conferito in precedenza. Questa operazione ripristiner&agrave; la comparsa dei messaggi informativi circa la presenza di cookie nel sito.</p>

    <div class="table-responsive">
      <table border="0" cellpadding="1">
        <tbody>
          <tr>
            <td style="width: 200px;" v-on:click="setCookieChoice(false)"><input id="RevocaConsenso" name="cookieConsenso" type="radio" value="1" />&ensp;Revoca il consenso</td>
            <td style="width: 200px;" v-on:click="setCookieChoice(true)"><input id="ConfermaConsenso" name="cookieConsenso" type="radio" value="0" />&ensp;Conferma il consenso</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>&ensp;</p>

    </div>
  </div>
</div>
<!-- /.container -->
</template>

<script>
import CookieChoice from '@/components/CookieChoice.vue'
//per la gestione dei cookies
import Vue from 'vue'
Vue.use(require('vue-cookies'))

export default {
  name: 'Informazioni',
  props: {
    msg: String
  },
  components: {
    CookieChoice,
  },
  methods: {
    setCookieChoice(bool){
      if(bool){this.$refs.cookiechoice.accettoCookies();} //conferma
      else{this.$refs.cookiechoice.revocoCookies();} //revoca
    },
    VerificaCookiesConsenso() {
          if(this.$cookies.isKey('displayCookieConsent')){
              if(this.$cookies.get('displayCookieConsent')=='y'){
                document.getElementById("ConfermaConsenso").checked = true;
              }
              else{
                document.getElementById("RevocaConsenso").checked = true;
              }
          }
          else{
            document.getElementById("RevocaConsenso").checked = true;
          }
    },

  },
  mounted() {
    this.VerificaCookiesConsenso();
  },
  data() {
    return {
      banner: {
        src: require('../../public/SCTProfessional/static/images/banner_info.jpg')
      },
      VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>

</style>
