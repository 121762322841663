/* eslint-disable */
<template>
  <!-- Page Content -->
  <div class="container container-interno">

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Invia segnalazione</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
            Invia segnalazione sui dati pubblicati
          </h3>
        </div>
      </div>
    </div>

    <div class="container-pagine-interne">
      <div class="spazio_sopra spazio_sotto">

        <vue-element-loading :active="loading" :is-full-screen="true" background-color="rgba(211,211,211,.6)"
          text="Caricamento...">
          <div class="d-flex justify-content-center">
            <div class="spinner-border text-primary" role="status">
            </div>
          </div>
        </vue-element-loading>
        <vue-snotify></vue-snotify>

        <vue-form-generator @validated="onValidated" ref="vfg" tag="div"
          :schema="schemaInviaSegnalaz" :model="user" :options="formOptionsInviaSegnalaz">
        </vue-form-generator>

        <FileUpload ref="curFileUpload"></FileUpload>

        <div class="col text-center" v-if="!loading">
          <button id="btnSendMail" class="btn btn-lg btn-success" @click="inviaSegnalazione">
            <i class="far fa-envelope">&nbsp;&nbsp;<span class="far">INVIA</span></i>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- /.container -->
</template>

<script>
  // import Auth from '@/services/api/Auth_User.js'
  import InvioSegnalaz from '@/scripts/InvioSegnalazione.js'
  import AuthInvioSegnalaz from '@/services/api/InvioSegnalazione.js'
  //vue-form-generator (con integrato il recaptcha)
  import VueFormGenerator from 'vue-form-generator'
  import 'vue-form-generator/dist/vfg-core.css'
  VueFormGenerator.validators.resources.fieldIsRequired = "Campo obbligatorio";
  VueFormGenerator.validators.resources.invalidEmail = "Indirizzo e-mail non valido";
  //loading
  import VueElementLoading from 'vue-element-loading'
  //upload
  import FileUpload from '@/components/VueUploadComponent.vue'
  //notifiche
  import Vue from 'vue'
  import Snotify, {
    SnotifyPosition
  } from 'vue-snotify'
  import 'vue-snotify/styles/material.css'
  const optionsSnotify = {
    toast: {
      position: SnotifyPosition.centerCenter,
      titleMaxLength: 150,
      bodyMaxLength: 300,
    }
  }
  Vue.use(Snotify, optionsSnotify);

  export default {
    name: 'InvioSegnalazione',
    props: {
      msg: String,
      tipoutilizzo: '', //auth oppure noauth
      user: {
        default: function () {
          return {}
        },
        type: Object
      }
    },
    data() {
      return {
        loading: true,
        //definizione vue-form-generator
        modelInviaSegnalaz: this.$props.user, //dati
        schemaInviaSegnalaz: {},
        formOptionsInviaSegnalaz: InvioSegnalaz.getFormOptionsInviaSegnalaz(), //le opzioni
        isValid: false,
        error: '',
        banner: {
          src: require('../../public/SCTProfessional/static/images/banner_contact.jpg')
        },
        VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
      }
    },
    components: {
      "vue-form-generator": VueFormGenerator.component,
      VueElementLoading,
      FileUpload,
      //FileUpload: VueUploadComponent,
      //Recaptcha
    },
    methods: {
      rimuoviTuttiAllegati() {
        var files = this.$refs.curFileUpload.files;
        for (var i = 0; i < files.length; i++) {
          this.$refs.curFileUpload.$refs.upload.remove(files[i]);
        }
      },
      init() {
        this.loading = true;
        this.schemaInviaSegnalaz = InvioSegnalaz.getSchemaInviaSegnalaz(this.$props.tipoutilizzo);
        this.rimuoviTuttiAllegati();
        this.loading = false
        /*
        if (this.$props.tipoutilizzo == 'noauth') {
          this.modelInviaSegnalaz = {};
        } else {
          this.modelInviaSegnalaz = this.$props.user;
        }*/
      },
      onValidated(isValid /*, errors*/ ) {
        //console.log('isValid',isValid);
        this.isValid = isValid;
      },
      inviaSegnalazione: function () {
        var errorInviaSegnalaz = '';
        //console.log('inviaSegnalazione-isValid',this.isValid);
        if (this.isValid) {
          this.loading = true;
          // var arrjson = InvioSegnalaz.preparaJSon_crud(this.schemaInviaSegnalaz, this.modelInviaSegnalaz);
          var arrjson = InvioSegnalaz.preparaJSon_crud(this.schemaInviaSegnalaz, this.$props.user);
          // console.log('arrjson',arrjson);
          AuthInvioSegnalaz.SendMailSegnalaz(arrjson)
            .then(ret => {
              //console.log('ret', ret);
              errorInviaSegnalaz = ret;
            })
            .catch(function (error) {
              errorInviaSegnalaz = error;
            })
            .finally(() => {
              var corpo = '';
              var titolo = '';
              this.loading = false;
              this.error = errorInviaSegnalaz;
              if (this.error != '') {
                if (this.error != "True") {
                  console.error(this.error);
                  if (this.error == "False|RECAPTCHA") {
                    titolo = 'Segnalazione non inoltrata';
                    corpo = 'Eseguire il test del reCAPTCHA';
                  } else {
                    titolo = 'Operazione fallita';
                    corpo = this.error;
                  }
                  this.$snotify.error(
                    corpo,
                    titolo, {
                      position: SnotifyPosition.centerCenter,
                      titleMaxLength: 150,
                      bodyMaxLength: 300,
                      showProgressBar: false,
                      timeout: 0
                    }
                  );
                } else {
                  //resetto il controllo recaptcha
                  grecaptcha.reset();
                  //resetto i valori inseriti
                  this.init();
                  //messaggio di successo
                  corpo = 'Segnalazione inoltrata con successo';
                  titolo = 'Operazione completata';
                  this.$snotify.success(corpo, titolo);
                }
              }
            });
        } else {
          var corpo = 'Compilare tutti i campi obbligatori nella pagina';
          var titolo = 'Attenzione';
          //this.$snotify.warning(
          this.$snotify.error(
            corpo,
            titolo, {
              position: SnotifyPosition.centerCenter,
              titleMaxLength: 150,
              bodyMaxLength: 300,
              showProgressBar: true,
              timeout: 3000, //millisecondi
            }
          );
        }
      },

    }, //methods
    mounted() {
      this.init();
    } //mounted
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style>
  .snotifyToast__body {
    font-weight: bold !important;
  }

  .btn-success,
  .btn-success:hover {
    font-weight: 100;
    font-size: 15px;
  }

</style>
