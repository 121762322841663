<template>
  <b-modal centered  v-model="apriDialogIframe" id="dlgIframe" :title="title" hide-footer size="lg" height="80%">
      <span v-html="HtmlContents" />
  </b-modal>
</template>

<script>
/* eslint-disable */
import Common from '@/services/api/Common.js';

  export default {
    name: 'dialogiframe',
    data() {
      return {
        apriDialogIframe: false,
        src: '',
        title: '',
        HtmlContents: '',
        error:''
      }
  },
  updated() {
    this.populateDiv();
  },
  methods: {
    populateDiv() {
      let errorMounted = '';
      Common.webScraping(this.src)
        .then(risultato => {
          this.HtmlContents =
          risultato.content
        })
        .catch(function (error) {
          this.error = errorMounted;
        })
        .finally(() => {
          this.error = errorMounted;
          if (this.error != '') {
            console.error(this.error);
          }
        });
    }
  }
}
</script>
<style>
/*
#dlgIframe___BV_modal_body_ {
  height: 700px !important;
}
*/    
</style>
