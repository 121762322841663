import axios from 'axios'
import Common from '@/scripts/Common.js';

//API per repertori
function getUrlNovita(param) {
  var url = '/SCTProfessional/repertcarto/callauth/json/get_rows_novita';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/call/json/get_rows_novita';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function getUrlDaAggiornare(param) {
  var url = '/SCTProfessional/repertcarto/callauth/json/get_rows_daaggiornare';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/call/json/get_rows_daaggiornare';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function getUrlScaricati(param) {
  var url = '/SCTProfessional/repertcarto/callauth/json/get_rows_scaricati_update';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/call/json/get_rows_scaricati_update';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function getUrlDispComplessiva(param) {
  var url = '/SCTProfessional/repertcarto/callauth/json/get_rows_dascaricare';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/call/json/get_rows_dascaricare';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function getUrlAllRepertori(param) {
  var url = '/SCTProfessional/repertcarto/call/json/get_rows_alldascaricare';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/call/json/get_rows_alldascaricare';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function getUrlApriDocFile(param) {
  var url = '/SCTProfessional/repertcarto/apriDocFile';
  /*
  if (process.env.NODE_ENV === 'development') {
    url = '/SCTProfessional/repertcarto/apriDocFile';
    url = 'http://localhost:8000' + url;
  }*/
  url = url + '?param=' + param;
  return url;
}

function get_img_sctoutil_libre() {
  return require('@/images/LibreH.png')
}

function get_img_sctoutil_pro() {
  return require('@/images/ProH.png')
}

export default {
  formatBoolRiservato(rowObj) {
    return Common.formatBool(rowObj.riservato)
  },
  formatSCTOutil(rowObj) {
    if (rowObj.riservato == 'True') {
      // return 'PRO'
      return '<img src="' + get_img_sctoutil_pro() + '" class = "img-fluid" >'
    }
    if (rowObj.riservato == 'False') {
      // return 'Libre'
      return '<img src="' + get_img_sctoutil_libre() + '" class = "img-fluid" >'
    }
    return ''
  },
  apriDocFile(param) {
    window.open(getUrlApriDocFile(param), '_blank')
  },
  getRepertNovita(param) {
    return axios
      .get(getUrlNovita(param))
      .then(response => {
        return response.data;
      })
  },
  getRepertDaAggiornare(param) {
    return axios
      .get(getUrlDaAggiornare(param))
      .then(response => {
        return response.data;
      })
  },
  getRepertScaricati(param) {
    return axios
      .get(getUrlScaricati(param))
      .then(response => {
        return response.data;
      })
  },
  getRepertDispComplessiva(param) {
    return axios
      .get(getUrlDispComplessiva(param))
      .then(response => {
        return response.data;
      })
  },
  getRepertAllRepertori(param) {
    return axios
      .get(getUrlAllRepertori(param))
      .then(response => {
        return response.data;
      })
  },
  //definizione colonne e righe per vue-good-table
  getColsRepertScaricati(obj) {
    return [{
        field: 'id',
        label: 'ID',
        type: 'number',
        width: 30,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'objectid',
        label: 'objectid',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'ordine',
        label: 'ordine',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'tipo_record',
        label: 'tipo_record',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'categoria',
        label: 'Categoria',
        width: "15%",
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'denominazione',
        label: 'Livello',
        width: "50%",
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'datapub',
        label: 'Pubblicato o aggiornato il',
        width: "15%",
        hidden: false,
        sortFn: Common.dataStringSort,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'data_scarico',
        label: 'Scaricato il',
        width: "15%",
        sortFn: Common.dataStringSort,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'chiave_layer',
        label: 'Livello',
        width: 100,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'metadato',
        label: 'metadato',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'datatipo',
        label: 'datatipo',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'isnull_note',
        label: 'isnull_note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'note',
        label: 'note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'htmlurl_note',
        label: 'Condizioni dello strato',
        html: true,
        hidden: false,
        width: "15%",
        sortable: false,
        tdClass: obj.tdClassRepertScaricati
        //dataClass: 'center aligned'
        //formatFn: this.NoteFormatterScaricati
      },
      {
        field: 'isnull_metadato',
        label: 'isnull_metadato',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertScaricati
      },
      {
        field: 'htmlurl_metadato',
        label: 'Metadato',
        html: true,
        hidden: false,
        sortable: false,
        width: "15%",
        tdClass: obj.tdClassRepertScaricati
        //formatFn: this.MetadatoFormatter
      },
      {
        field: 'htmlurl_download',
        label: 'Download',
        width: "15%",
        html: true,
        hidden: false,
        sortable: false,
        tdClass: obj.tdClassRepertScaricati
      },
    ];
  },
  getColsRepertNovita(obj) {
    return [{
        field: 'id',
        label: 'ID',
        type: 'number',
        width: 30,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'objectid',
        label: 'objectid',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'ordine',
        label: 'ordine',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'tipo_record',
        label: 'tipo_record',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'datapub',
        label: 'Pubblicato o aggiornato il',
        width: 50,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'categoria',
        label: 'Categoria',
        width: "15%",
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'denominazione',
        label: 'Livello',
        width: "50%",
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'chiave_layer',
        label: 'Livello',
        width: 100,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'metadato',
        label: 'metadato',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'datatipo',
        label: 'datatipo',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'isnull_note',
        label: 'isnull_note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'note',
        label: 'note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'htmlurl_note',
        label: 'Condizioni dello strato',
        hidden: false,
        html: true,
        width: "15%",
        sortable: false,
        tdClass: obj.tdClassRepertNovita
        //dataClass: 'center aligned'
        //formatFn: this.NoteFormatterScaricati
      },
      {
        field: 'isnull_metadato',
        label: 'isnull_metadato',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertNovita

      },
      {
        field: 'htmlurl_metadato',
        label: 'Metadato',
        hidden: false,
        html: true,
        width: "15%",
        sortable: false,
        tdClass: obj.tdClassRepertNovita
        //formatFn: this.MetadatoFormatter
      },
      {
        field: 'data_scarico',
        label: 'Ultimo download',
        width: "15%",
        hidden: true,
        tdClass: obj.tdClassRepertNovita
      },
      {
        field: 'htmlurl_download',
        label: 'Download',
        width: "15%",
        html: true,
        hidden: false,
        sortable: false,
        tdClass: obj.tdClassRepertNovita
      },
    ];
  },
  getColsRepertDaAggiornare(obj) {
    return [{
        field: 'id',
        label: 'ID',
        type: 'number',
        width: 30,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'objectid',
        label: 'objectid',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'ordine',
        label: 'ordine',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'tipo_record',
        label: 'tipo_record',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'datapub',
        label: 'Pubblicato o aggiornato il',
        width: "15%",
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'categoria',
        label: 'Categoria',
        width: "15%",
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'denominazione',
        label: 'Livello',
        width: "50%",
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'chiave_layer',
        label: 'Livello',
        width: 100,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'metadato',
        label: 'metadato',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'datatipo',
        label: 'datatipo',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'isnull_note',
        label: 'isnull_note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'note',
        label: 'note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'htmlurl_note',
        label: 'Condizioni dello strato',
        hidden: false,
        html: true,
        width: "15%",
        sortable: false,
        tdClass: obj.tdClassRepertDaAggiornare
        //dataClass: 'center aligned'
        //formatFn: this.NoteFormatterScaricati
      },
      {
        field: 'isnull_metadato',
        label: 'isnull_metadato',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDaAggiornare

      },
      {
        field: 'htmlurl_metadato',
        label: 'Metadato',
        hidden: false,
        html: true,
        width: "15%",
        sortable: false,
        tdClass: obj.tdClassRepertDaAggiornare
        //formatFn: this.MetadatoFormatter
      },
      {
        field: 'htmlurl_download',
        label: 'Download',
        width: "15%",
        html: true,
        hidden: false,
        sortable: false,
        tdClass: obj.tdClassRepertDaAggiornare
      },
      {
        field: 'data_scarico',
        label: 'Ultimo download',
        width: "15%",
        tdClass: obj.tdClassRepertDaAggiornare
      },
    ];
  },
  getColsRepertDispComplessiva(obj) {
    var ishiddensctoutil = false
    /*
    if (obj.user.statoabbonamento == 3) {
      ishiddensctoutil = true
    }
    */
    return [{
        field: 'id',
        label: 'ID',
        type: 'number',
        width: 30,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'objectid',
        label: 'objectid',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'ordine',
        label: 'ordine',
        type: 'number',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'tipo_record',
        label: 'tipo_record',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'categoria',
        label: 'Categoria',
        width: "15%",
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'denominazione',
        label: 'Livello',
        width: "15%",
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'chiave_layer',
        label: 'Livello',
        width: 100,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'metadato',
        label: 'metadato',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'datatipo',
        label: 'datatipo',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'isnull_note',
        label: 'isnull_note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'note',
        label: 'note',
        width: 75,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'datapub',
        label: 'Pubblicato o aggiornato il',
        width: "15%",
        sortFn: Common.dataStringSort,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'data_scarico',
        label: 'Scaricato il',
        width: "12%",
        hidden: false,
        sortFn: Common.dataStringSort,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'htmlurl_note',
        label: 'Condizioni dello strato',
        hidden: false,
        html: true,
        width: "10%",
        sortable: false,
        tdClass: obj.tdClassRepertDispComplessiva
        //dataClass: 'center aligned'
        //formatFn: this.NoteFormatterScaricati
      },
      {
        field: 'isnull_metadato',
        label: 'isnull_metadato',
        width: 50,
        hidden: true,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: 'htmlurl_metadato',
        label: 'Metadato',
        hidden: false,
        html: true,
        width: "10%",
        sortable: false,
        tdClass: obj.tdClassRepertDispComplessiva
        //formatFn: this.MetadatoFormatter
      },
      {
        field: 'htmlurl_download',
        label: 'Download',
        width: "10%",
        html: true,
        hidden: false,
        sortable: false,
        tdClass: obj.tdClassRepertDispComplessiva
      },
      {
        field: this.formatSCTOutil,
        label: 'SCT-Outil',
        html: true,
        width: "15%",
        hidden: ishiddensctoutil,
      },
    ];
  },
  getColsRepertAllRepertori(url_libre, url_pro) {
    return [{
        field: 'id',
        label: 'ID',
        type: 'number',
        width: 30,
        hidden: true
      },
      {
        field: 'objectid',
        label: 'objectid',
        type: 'number',
        width: 50,
        hidden: true
      },
      {
        field: 'ordine',
        label: 'ordine',
        type: 'number',
        width: 50,
        hidden: true
      },
      {
        field: 'tipo_record',
        label: 'tipo_record',
        width: 50,
        hidden: true,
      },
      {
        field: 'datapub',
        label: 'Pubblicato o aggiornato il',
        width: 50
      },
      {
        field: 'categoria',
        label: 'Categoria',
        width: "15%",
      },
      {
        field: 'denominazione',
        label: 'Livello',
        width: "40%",
      },
      {
        field: 'chiave_layer',
        label: 'Livello',
        width: 100,
        hidden: true,
      },
      {
        field: 'metadato',
        label: 'metadato',
        width: 75,
        hidden: true,
      },
      {
        field: 'datatipo',
        label: 'datatipo',
        width: 75,
        hidden: true
      },
      {
        field: 'isnull_note',
        label: 'isnull_note',
        width: 75,
        hidden: true
      },
      {
        field: 'note',
        label: 'note',
        width: 75,
        hidden: true,
      },
      {
        field: 'htmlurl_note',
        label: 'Condizioni dello strato',
        html: true,
        hidden: false,
        sortable: false,
        width: "10%"
        //dataClass: 'center aligned'
        //formatFn: this.NoteFormatterScaricati
      },
      {
        field: 'isnull_metadato',
        label: 'isnull_metadato',
        width: 50,
        hidden: true,
      },
      {
        field: 'htmlurl_metadato',
        label: 'Metadato',
        html: true,
        hidden: false,
        sortable: false,
        width: "10%"
        // this.MetadatoFormatter
      },
      {
        field: 'data_scarico',
        label: 'Ultimo download',
        width: "15%",
        hidden: true
      },
      {
        field: this.formatSCTOutil,
        label: 'SCT-Outil',
        html: true,
        width: "15%",
      },
    ];
  }



}
