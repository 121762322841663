/* eslint-disable */
<template>
<!-- Page Content -->
<div class="container container-interno">

  <!--- Banner --->
  <div class="banner">
    <img :src="banner.src" class="img-fluid">
  </div>

  <!-- Page Heading/Breadcrumbs -->
  <div> 
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
      </li>
      <li class="breadcrumb-item active">Novità</li>
    </ol>
  </div>

  <!--- Titolo Pagina --->
  <div class="row">
    <div class="col">
      <div class="card h-100">
        <h3 class="card-header card-titolo">
            Novità
        </h3>
      </div>
    </div>
  </div>

  <!--- Contenuto pagina --->
  <div class="container-pagine-interne">
  
    <div class="mb-4" id="accordion" role="tablist" aria-multiselectable="true">
    
      <vue-element-loading :active="loading" :is-full-screen="true" 
      background-color="rgba(211,211,211,.6)" text="Caricamento...">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
        </div>
      </div>
      </vue-element-loading>

      <div class="card" v-for="(news,index) in allnews" :key="index">

        <div class="card-header" role="tab" :id="news.id">
          <h5 class="mb-0">
            <a data-toggle="collapse" data-parent="#accordion" :href="'#collapse_'+news.id" 
            aria-expanded="true" :aria-controls="'collapse_'+news.id">
              {{news.datanews}} - {{news.titolo}}
            </a>
          </h5>
        </div>

        <div v-if="index > 0" :id="'collapse_'+news.id" class="collapse show" role="tabpanel" :aria-labelledby="news.id">
          <div class="card-body">
            <div v-html="news.descrizione">
            </div>            
            <div v-if="news.url != '' ">
              <br>
              <a :href="news.url" target='blank'>{{news.url_tag}}</a>
            </div>
            <div v-if="news.allegato_url != ''">
              <br>
              <a :href="news.allegato_tag" target='blank'>{{news.allegato_tag}}</a>
            </div>
          </div>
        </div>
        
        <div v-else :id="'collapse_'+news.id" class="collapse show" role="tabpanel" :aria-labelledby="news.id">
          <div class="card-body">
            <div v-html="news.descrizione">
            </div>            
            <div v-if="news.url != '' ">
              <br>
              <a :href="news.url" target='blank'>{{news.url_tag}}</a>
            </div>
            <div v-if="news.allegato_url != ''">
              <br>
              <a :href="news.allegato_tag" target='blank'>{{news.allegato_tag}}</a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

</div>
<!-- /.container -->
</template>

<script>
/*
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
*/
import NewsApi from '@/services/api/News.js'
import VueElementLoading from 'vue-element-loading'
var VueScrollTo = require('vue-scrollto');

export default {
  name: 'Novità',
  props: {
    msg: String
  },
  components:{
      VueElementLoading
    },
  methods:{
    scrollTo(cururl){
       if(cururl.split("#").length>1){
           var options = {
                    onStart: function(element) {
                      //console.log('onStart');
                    },
                    onDone: function(element) {
                      console.log('onDone');
                      //scrollo ancora un po per posizionarmi correttamente
                      var lagh_header=93;
                      var lagh_elem_id=48;
                      var delta=lagh_header+lagh_elem_id;
                      window.scroll(0, window.scrollY-delta);
                    },
                }
         VueScrollTo.scrollTo("#"+cururl.split("#")[1],500,options)
       }
    },
  },
   data() {
    return {
      loading: true,
      allnews: [],
      error:'',
      banner: {
        src: require('../../public/SCTProfessional/static/images/banner_info.jpg')
      },
      VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
    }
   },
  mounted(){
    var errorMounted='';
    NewsApi.getAllNews()
      .then(allnews => {this.allnews = allnews})
      .catch(function(error){
          errorMounted=error;  
        })
      .finally(() => {
        this.loading=false
        this.error=errorMounted;
        if(this.error!=''){
          console.error(this.error);
        }
        //mi posiziono sull'elemento desiderato
        this.scrollTo(window.location.href);
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>
.loading-backdrop.div.loading{
background-color: transparent !Important;
box-shadow: none !Important;
}
</style>
