/* eslint-disable */
<template>
<vue-recaptcha 
      :sitekey="recaptchaSitekey" 
      :loadRecaptchaScript="true"
      class="g-recaptcha"
      >
</vue-recaptcha>
</template>

<script>
//recaptcha
  import VueRecaptcha from 'vue-recaptcha';
  import Common from '@/scripts/Common.js'
  import { abstractField } from "vue-form-generator";

  export default {
      mixins: [ abstractField ],
      //name: 'Recaptcha',
      name: 'fieldRecaptcha',
      data() {
          return {
              recaptchaSitekey: this.getRecaptchaSitekey()
          }
      },
      components: {
          VueRecaptcha 
      },
      methods: {
          getRecaptchaSitekey(){
            if(Common.getAMBIENTE()=='SVI'){
            return '6Lc9cEAUAAAAAC8uyg2Q4Bq_cp4Km-XLJiBsum_u';
            }
            if(Common.getAMBIENTE()=='PRE'){
            return '6LfmwUAUAAAAACrRroM6JMqVRpMpnto9-tLfJtAA';
            }
            else{
            return '6LfHzEAUAAAAAKzOri1S0AH3fpE2CgkNYFIYT06_';
            }
          },

      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>

</style>
