<template>
  <header>
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselIndicators" data-slide-to="1"></li>
        <li data-target="#carouselIndicators" data-slide-to="2"></li>
        <li data-target="#carouselIndicators" data-slide-to="3"></li>
      </ol>
      <div class="carousel-inner col-spazio_sotto" role="listbox">
        <!-- Slide One - Set the background image for this slide in the line below -->
        <!--1900x1080-->
        <div class="carousel-item active" :style="{ 'background-image': 'url(' + bigriquadronew1.src + ')' }">
          <div class="carousel-caption d-md-block">
            <!--d-none classe che lo nasconde su mobile-->
            <h3><a :href="VUE_APP_HREFCARTAGEO" class="hrefcartageo canvas_item_href">Geonavigatore</a></h3>
            <!--<p>This is a description for the first slide.</p>-->
          </div>
        </div>
        <!-- Slide Two - Set the background image for this slide in the line below -->
        <div class="carousel-item" :style="{ 'background-image': 'url(' + bigriquadronew2.src + ')' }">
          <div class="carousel-caption d-md-block">
            <h3><a :href="VUE_APP_HREFMAPPECAT" class="hrefmappecat canvas_item_href">Servizio Catasto</a></h3>
            <!--
          <p>This is a description for the second slide.</p>
          -->
          </div>
        </div>
        <!-- Slide Three - Set the background image for this slide in the line below -->
        <div class="carousel-item" :style="{ 'background-image': 'url(' + bigriquadronew3.src + ')' }">
          <div class="carousel-caption d-md-block">
            <h3><a :href="VUE_APP_HREFDOWNLOADFILES" class="hrefdownloadfiles canvas_item_href">Strumenti</a></h3>
            <!--<p>This is a description for the third slide.</p>-->
          </div>
        </div>
        <!-- Slide fourth - Set the background image for this slide in the line below -->
        <div class="carousel-item" :style="{ 'background-image': 'url(' + bigriquadronew4.src + ')' }">
          <div class="carousel-caption d-md-block">
            <h3><a :href="VUE_APP_HREFREPERTCARTO" class="hrefrepertcarto canvas_item_href">Repertorio Cartografico</a>
            </h3>
            <!--
          <p>This is a description for the fourth slide.</p>
          -->
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Precedente</span>
      </a>
      <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Successivo</span>
      </a>
    </div>


    <!-- Page Content -->
    <div class="container">


      <!-- Bottoni Demo -->
      <div class="row col-spazio_sotto">
        <div class="col-lg-6 mb-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="card-text text-center">
                <img class="img-thumbnail-bianco imgcustsat" :src="bottone_gradimento.src">
                &ensp;&ensp;<a :href="VUE_APP_URLDOCGOOGLE" target="_blank">
                  <h4>Indagine di gradimento</h4>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-6">
          <div class="card h-100">
            <div class="card-body">
              <p class="card-text text-center">
                <img class="img-thumbnail-bianco imgdemo sfondo-bianco" :src="bottone_demo.src">
                &ensp;&ensp;<a class="hrefdemo" :href="VUE_APP_HREFDEMO">
                  <h4>Webinar SCT-Outil</h4>
                </a>
              </p>
            </div>
          </div>

        </div>
      </div>
      <!-- /.row -->
      <!-- Fine: Bottoni Demo-->

      <!-- News -->
      <div class="row">
        <div class="col">
          <div class="card-t h-100">
            <h3 class="card-header card-titolo">
              News&nbsp;
            </h3>
          </div>
        </div>
      </div>

      <div id="hp_news" class="row">
        <div class="col col-spazio_sotto">
          <div class="card h-100">
            <div class="card-body">
              <NewsSwiper></NewsSwiper>
            </div>
            <div class="card-footer text-right">
              <a :href="VUE_APP_HREFNOVITA" class="btn btn-success hrefnovita">Per accedere a tutte le news</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine: News-->

      <!-- Informazioni -->
      <div id="hp_info" class="row col-spazio_sotto" v-if="!ifMobile">
        <div class="col-lg-12 mb-6">
          <div class="card h-100">
            <h4 class="card-header">Informazioni</h4>
            <div class="card-body">
              <p class="card-text-info">Il servizio <b>SCT-Outil</b> è un servizio web rivolto ad un'utenza tecnica
                costituito
                da una serie di funzionalità specialistiche per la visualizzazione, interrogazione e trattamento
                dei dati territoriali e dei contenuti cartografici presenti in SCT (Sistema delle Conoscenze
                Territoriali)
                della Regione Autonoma Valle d'Aosta.
              </p>
              <!--
              <p class="card-text-info">
                <ul>
                  <li>
                    <b>GeoNavigatore SCT specializzato per un uso desktop che fornisce funzionalità specialistiche
                      nell'accesso ai dati territoriali.</b>
                  </li><br>
                  <li><b>WEB App cartografica con funzionalità orientate all'uso in mobilità (smartphone e tablet).</b>
                  </li>
                </ul>
              </p>
              -->
              <p>
                <b>SCT-Outil</b> si compone in due moduli specializzati:
              </p>

              <div class="container">
                <div class="row">
                  <div class="col-3">
                    <img :src="img_sctoutil_libre.src" class="img-fluid">
                  </div>
                  <div class="col-9 my-auto">
                    modulo gratuito e riservato agli utenti registrati.
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <img :src="img_sctoutil_pro.src" class="img-fluid">
                  </div>
                  <div class="col-9 my-auto">
                    modulo in abbonamento al <b>costo di 70 euro/anno con una validità di 365 giorni dalla
                      sottoscrizione</b>
                    come definito dalla <b>D.G.R n°51 del 22 gennaio 2018</b>.
                  </div>
                </div>
              </div>

            </div>
            <div v-if="tipoutilizzo == 'noauth'" class="card-footer text-right">
              <a :href="VUE_APP_HREFPROGETTO" class="btn btn-success hrefprogetto_noauth">Per saperne di più</a>
            </div>
            <div v-else class="card-footer text-right">
              <a :href="VUE_APP_HREFPROGETTO" class="btn btn-success hrefprogetto_auth">Per saperne di più</a>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine: Informazioni -->

      <!-- Servizio Gratuito-->
      <div id="hp_servpub" class="row">
        <div class="col">
          <div class="card h-100">
            <h3 class="card-header card-titolo">
              <img :src="img_sctoutil_libre.src" class="img-fluid">
            </h3>
          </div>
        </div>
      </div>
      <div id="hp_repertcartopub" class="col-lg-4 col-sm-6 portfolio-item">
        <div class="card h-100">
          <a :href="VUE_APP_HREFREPERTCARTO" class="hrefrepertcarto"><img class="card-img-top"
              :src="img_repertorio_libre.src" alt=""></a>
          <div class="card-body">
            <h4 class="card-title">
              <a :href="VUE_APP_HREFREPERTCARTO" class="hrefrepertcarto">Repertorio Cartografico Gratuito</a>
            </h4>
            <p class="card-text">
              Sistema di download dei dati cartografici pubblici di SCT che
              permette all'utente di scaricare localmente l’ultima versione delle informazioni territoriali di interesse
              e
              di mantenere
              sotto controllo l'eventuale aggiornamento/ nuova disponibilità di tali informazioni.
            </p>
          </div>
        </div>
      </div>

      <!-- Servizi Riservati-->
      <div id="hp_servris" class="row">
        <div class="col">
          <div class="card h-100">
            <h3 class="card-header card-titolo">
              <img :src="img_sctoutil_pro.src" class="img-fluid">
            </h3>
          </div>
        </div>
      </div>
      <div id="hp_geonav" class="row">
        <div class="col-lg-4 col-sm-6 portfolio-item">
          <div class="card h-100">
            <a :href="VUE_APP_HREFCARTAGEO" class="hrefcartageo"><img class="card-img-top"
                :src="img_geonavigatore_pro.src" alt=""></a>
            <div class="card-body">
              <h4 class="card-title">
                <a :href="VUE_APP_HREFCARTAGEO" class="hrefcartageo">Geonavigatore</a>
              </h4>
              <p class="card-text">
                Agli utenti che aderiscono al pacchetto SCT-Outil PRO sono forniti i seguenti strumenti cartografici:
                <br><br>
              <ul>
                <li>GeoNavigatore SCT specializzato per un uso desktop che fornisce funzionalità
                  specialistiche nell'accesso ai dati territoriali</li>
                <li>WEB App cartografica con funzionalità orientate all'uso in mobilità (smartphone e tablet)</li>
              </ul>
              </p>
            </div>
          </div>
        </div>
        <div id="hp_servcat" class="col-lg-4 col-sm-6 portfolio-item">
          <div class="card h-100">
            <a :href="VUE_APP_HREFMAPPECAT" class="hrefmappecat"><img class="card-img-top" :src="img_servcat_pro.src"
                alt=""></a>
            <div class="card-body">
              <h4 class="card-title">
                <a :href="VUE_APP_HREFMAPPECAT" class="hrefmappecat">Servizio Catasto</a>
              </h4>
              <p class="card-text">In questa sezione del sito è disponibile la procedura di conversione dei
                file catastali dal formato CMF forniti dall’Agenzia delle Entrate verso il formato SHP conforme
                alle strutture dati e al sistema di coordinate di SCT.</p>
            </div>
          </div>
        </div>
        <div id="hp_strum" class="col-lg-4 col-sm-6 portfolio-item">
          <div class="card h-100">
            <a :href="VUE_APP_HREFDOWNLOADFILES" class="hrefdownloadfiles"><img class="card-img-top"
                :src="img_strumenti_pro.src" alt=""></a>
            <div class="card-body">
              <h4 class="card-title">
                <a :href="VUE_APP_HREFDOWNLOADFILES" class="hrefdownloadfiles">Strumenti</a>
              </h4>
              <p class="card-text">
                In questa sezione del sito sono disponibili vari strumenti specializzati realizzati per
                favorire le attività lavorative cartografiche dei professionisti in ambito Gis Desktop.
              </p>
            </div>
          </div>
        </div>
        <div id="hp_repertcarto" class="col-lg-4 col-sm-6 portfolio-item">
          <div class="card h-100">
            <a :href="VUE_APP_HREFREPERTCARTO" class="hrefrepertcarto"><img class="card-img-top"
                :src="img_repertorio_pro.src" alt=""></a>
            <div class="card-body">
              <h4 class="card-title">
                <a :href="VUE_APP_HREFREPERTCARTO" class="hrefrepertcarto">Repertorio Cartografico Completo</a>
              </h4>
              <p class="card-text">
                Sistema completo di download dei dati cartografici di SCT che
                permette all'utente di scaricare localmente l’ultima versione delle informazioni territoriali complete
                di
                interesse e
                di mantenere
                sotto controllo l'eventuale aggiornamento/ nuova disponibilità di tali informazioni.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Fine: Servizi Riservati-->

      <!-- Adesione e Contatti-->
      <div id="hp_adescont" class="row col-spazio_sotto" v-if="!ifMobile">
        <div class="col-lg-6 mb-6">
          <div class="card h-100">
            <h4 class="card-header">Adesione</h4>
            <div class="card-body">
              <p class="card-text"> Per aderire ai servizi è necessario prima completare il processo di
                <b>registrazione</b>
                che assicura l'<b>accesso illimitato ai servizi gratuiti</b>.
              </p>
              <p class="card-text"> Coloro che desiderano accedere ai servizi riservati dovranno successivamente
                <b>pagare la quota annuale di 70 euro (nuova iscrizione o proroga abbonamento)</b>.
              </p>
            </div>
            <div v-if="tipoutilizzo == 'noauth'" class="card-footer text-right">
              <a :href="VUE_APP_HREFADESIONE" class="btn btn-success hrefadesione_noauth">Per saperne di più</a>
            </div>
            <div v-else class="card-footer text-right">
              <a :href="VUE_APP_HREFADESIONE" class="btn btn-success hrefadesione_auth">Per saperne di più</a>
            </div>
          </div>
        </div>

        <div class="col-lg-6 mb-6">
          <div class="card h-100">
            <h4 class="card-header">Contatti</h4>
            <div class="card-body">
              Per ricevere <b>supporto da personale specializzato</b> sono disponibili:
              <div class="table-responsive spazio_sopra">
                <table class="">
                  <tbody>
                    <tr>
                      <td style="width:15%" class="align-middle text-center">
                        <a href="mailto:infoservizi@regione.vda.it" class="align-middle">
                          <i title="Invio mail" class="far fa-envelope fa-2x"></i>
                        </a>
                      </td>
                      <td style="width:85%;">
                        <a href="mailto:infoservizi@regione.vda.it" class="align-middle">
                          <b>Assistenza tramite posta elettronica</b>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style="width:15%" class="align-middle text-center">
                        <img :src="telefono.src">
                      </td>
                      <td style="width:85%">
                        <b>Assistenza tramite numero verde </b> &nbsp;&nbsp;&nbsp; <img :src="numeroverde.src"
                          style="padding-left:15px;">
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td v-if="tipoutilizzo == 'noauth'" class="align-middle text-center" style="width:15%">
                        <a :href="VUE_APP_HREFINVIOEMAIL_NOAUTH" class="urlmail hrefinvioemail_noauth">
                          <i title="Invio segnalazione" class="far fa-edit fa-2x"></i>
                        </a>
                      </td>
                      <td v-else class="align-middle text-center" style="width:15%">
                        <a :href="VUE_APP_HREFINVIOEMAIL_AUTH" class="urlmail hrefinvioemail_auth">
                          <i title="Invio segnalazione" class="fas fa-edit fa-2x"></i>
                        </a>
                      </td>

                      <td v-if="tipoutilizzo == 'noauth'" style="width:85%">
                        Modulo per <a :href="VUE_APP_HREFINVIOEMAIL_NOAUTH" class="urlmail hrefinvioemail_noauth">
                          <b>segnalazioni e osservazioni</b></a> sui dati pubblicati
                      </td>
                      <td v-else style="width:85%">
                        Modulo per <a :href="VUE_APP_HREFINVIOEMAIL_AUTH" class="urlmail hrefinvioemail_auth">
                          <b>segnalazioni e osservazioni</b></a> sui dati pubblicati
                      </td>

                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="tipoutilizzo == 'noauth'" class="card-footer text-right">
              <a :href="VUE_APP_HREFCONTATTI" class="btn btn-success hrefcontatti_noauth">Per saperne di più</a>
            </div>
            <div v-else class="card-footer">
              <a :href="VUE_APP_HREFCONTATTI" class="btn btn-success hrefcontatti_auth">Per saperne di più</a>
            </div>

          </div>
        </div>
      </div>
      <!-- Fine: Adesione e Contatti-->

    </div>
    <!-- /.container -->

  </header>

</template>

<script>
//testa tipo periferica: desktop o tablet oppure se mobile
import {
  isMobile
} from 'mobile-device-detect';
//controller che aggiunge le news
import NewsSwiper from '@/components/NewsSwiper.vue';
//import NewsMarquee from '@/components/NewsMarquee.vue';


export default {
  name: 'HomePage',
  props: {
    msg: String,
    tipoutilizzo: '', //auth oppure noauth
  },

  components: {
    NewsSwiper
  },

  data() {
    return {
      ifMobile: isMobile,
      bigriquadronew1: {
        src: require('../../public/SCTProfessional/static/images/geo_big.png')
      },
      bigriquadronew2: {
        src: require('../../public/SCTProfessional/static/images/catasto_big_freccia.png')
      },
      bigriquadronew3: {
        src: require('../../public/SCTProfessional/static/images/strumenti_big.png')
      },
      bigriquadronew4: {
        src: require('../../public/SCTProfessional/static/images/repertorio_big.png')
      },
      riquadronew1: {
        src: require('../../public/SCTProfessional/static/images/geo_pic.png')
      },
      riquadronew2: {
        src: require('../../public/SCTProfessional/static/images/catasto_pic.png')
      },
      riquadronew3: {
        src: require('../../public/SCTProfessional/static/images/strumenti_pic.png')
      },
      riquadronew4: {
        src: require('../../public/SCTProfessional/static/images/repertorio_pic.png')
      },

      numeroverde: {
        src: require('../../public/SCTProfessional/static/images/numero_verde.png')
      },
      telefono: {
        src: require('../../public/SCTProfessional/static/images/telefono.png')
      },
      bottone_gradimento: {
        src: require('../../public/SCTProfessional/static/images/indagine_gradimento.png')
      },
      bottone_demo: {
        src: require('../../public/SCTProfessional/static/images/play_demo.png')
      },
      VUE_APP_HREFCONTATTI: process.env.VUE_APP_HREFCONTATTI,
      VUE_APP_HREFCARTAGEO: process.env.VUE_APP_HREFCARTAGEO,
      VUE_APP_HREFMAPPECAT: process.env.VUE_APP_HREFMAPPECAT,
      VUE_APP_HREFDOWNLOADFILES: process.env.VUE_APP_HREFDOWNLOADFILES,
      VUE_APP_HREFREPERTCARTO: process.env.VUE_APP_HREFREPERTCARTO,
      VUE_APP_HREFDEMO: process.env.VUE_APP_HREFDEMO,
      VUE_APP_HREFPROGETTO: process.env.VUE_APP_HREFPROGETTO,
      VUE_APP_HREFADESIONE: process.env.VUE_APP_HREFADESIONE,
      VUE_APP_HREFINVIOEMAIL_NOAUTH: process.env.VUE_APP_HREFINVIOEMAIL_NOAUTH,
      VUE_APP_HREFINVIOEMAIL_AUTH: process.env.VUE_APP_HREFINVIOEMAIL_AUTH,
      VUE_APP_HREFNOVITA: process.env.VUE_APP_HREFNOVITA,
      VUE_APP_URLDOCGOOGLE: process.env.VUE_APP_URLDOCGOOGLE,
      img_sctoutil_libre: {
        src: require('@/images/Logo_SCT_Outil_Libre_small.png')
      },
      img_sctoutil_pro: {
        src: require('@/images/Logo_SCT_Outil_Pro_small.png')
      },
      img_repertorio_libre: {
        src: require('@/images/repertorio_pic_libre.png')
      },
      img_geonavigatore_pro: {
        src: require('@/images/geo_pic_pro.png')
      },
      img_servcat_pro: {
        src: require('@/images/catasto_pic_pro.png')
      },
      img_strumenti_pro: {
        src: require('@/images/strumenti_pic_pro.png')
      },
      img_repertorio_pro: {
        src: require('@/images/repertorio_pic_pro.png')
      },
    }
  },
  mounted() {
    this.tipoutilizzo = this.$props.tipoutilizzo;
  },
}

</script>

<style scoped>
/*#45403A*/
.canvas_item_href {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-block-end-color: rgb(255, 255, 255);
  border-block-start-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-inline-end-color: rgb(255, 255, 255);
  border-inline-start-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  caret-color: rgb(255, 255, 255);
  column-rule-color: rgb(255, 255, 255);
  fill: rgb(0, 0, 0);
  flood-color: rgb(0, 0, 0);
  lighting-color: rgb(255, 255, 255);
  stop-color: rgb(0, 0, 0);
  text-decoration-color: rgb(255, 255, 255);
  text-emphasis-color: rgb(255, 255, 255);
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

/*
.carousel-control-prev-icon,
.carousel-control-next-icon {
  position: relative;
  background-image: none;
}
.carousel-control-prev-icon:before
{
  font-family: "FontAwesome";
  font-weight: 900;
  content: "\f053";
  font-size: 48px;
}
.carousel-control-next-icon:before
{
  font-family: "FontAwesome";
  font-weight: 900;
  content: "\f054";
  font-size: 48px;
}
*/
.carousel-control-prev-icon,
.carousel-control-next-icon {
  /*display: inline-block;*/
  width: 30px;
  height: 30px;
  /*background: no-repeat 50% / 100% 100%;*/
}

.btn-success,
.btn-success:hover {
  font-weight: 100;
  font-size: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
