/* eslint-disable */
<template>
  <!-- Page Content -->
  <div class="container container-interno">
    <DialogIframe ref="dialogiframe"></DialogIframe>

    <!--- Banner --->
    <div class="banner">
      <img :src="banner.src" class="img-fluid">
    </div>

    <!-- Page Heading/Breadcrumbs -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a :href="VUE_APP_HREFHOME" class="hrefhome">Home</a>
        </li>
        <li class="breadcrumb-item active">Repertori cartografici disponibili</li>
      </ol>
    </div>

    <!--- Titolo Pagina --->
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h3 class="card-header card-titolo">
            Repertori cartografici disponibili
            <span class="float-right">
              <a class="pdfopendata" :href="VUE_APP_PDFOPENDATA" target="_blank"><img
                  title="Licenza Creative Commons Attribuzione 4.0 Internazionale (CC BY 4.0)" :src="ccby.src"
                  class="img-thumbnail"></a>
            </span>
          </h3>
        </div>
      </div>
    </div>

    <div class="container-pagine-interne spazio_sopra spazio_sotto">

      <h5 class="card-header">Disponibilità complessiva</h5>

      <vue-element-loading :active="loadingAllRepertori" :is-full-screen="true" background-color="rgba(211,211,211,.6)"
        text="Caricamento...">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
          </div>
        </div>
      </vue-element-loading>

      <vue-good-table :columns="columnsTableRepertAllRepertori" :rows="rowsTableRepertAllRepertori" :search-options="{
          enabled: true,
          placeholder: 'Cerca in tutti i repertori disponibili',
        }" :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom',
          perPage: 5,
          perPageDropdown: [5, 10, 20, 40, 80, 100],
          dropdownAllowAll: true,
          setCurrentPage: 1,
          nextLabel: '',
          prevLabel: '',
          rowsPerPageLabel: 'Righe per pagina',
          ofLabel: 'di',
          pageLabel: 'Pagina',
          allLabel: '[Tutte]',
        }" @on-cell-click="onClickCellTableRepertDispComplessiva">
        <div slot="emptystate" class="text-center">
          Nessun risultato
        </div>
      </vue-good-table>

    </div>

  </div>

  <!-- /.container -->
</template>

<script>
  import Common from '@/scripts/Common.js';
  import {
    VueGoodTable
  } from 'vue-good-table';
  import 'vue-good-table/dist/vue-good-table.css'
  import RepertCarto from '@/services/api/RepertoriCartografici.js'
  import Auth from '@/services/api/Auth_User.js'
  import VueElementLoading from 'vue-element-loading'
  import DialogIframe from '@/components/DialogIframe.vue';

  export default {
    name: 'RepertCartoDett',
    props: {
      msg: String
    },
    components: {
      VueGoodTable,
      VueElementLoading,
      DialogIframe
    },
    data() {
      return {
        AuthUsername: [],
        columnsTableRepertAllRepertori: RepertCarto.getColsRepertAllRepertori(require('@/images/Logo_SCT_Outil_Libre_small.png'),require('@/images/Logo_SCT_Outil_Pro_small.png')),
        rowsTableRepertAllRepertori: [],
        loadingAllRepertori: true,
        error: '',
        ccby: {
          src: require('../../public/SCTProfessional/static/images/CC-BY-120.png')
        },
        banner: {
          src: require('../../public/SCTProfessional/static/images/banner_repertorio.png')
        },
        VUE_APP_HREFHOME: process.env.VUE_APP_HREFHOME,
        VUE_APP_PDFOPENDATA: process.env.VUE_APP_PDFOPENDATA,
      }
  },
  methods: {
     onClickCellTableRepertDispComplessiva(params) {
      this.rowsTableRepertNovitaSelez = ''
      this.rowsTableRepertDaAggiornareSelez = ''
      this.rowsTableRepertScaricatiSelez = ''
      this.rowsTableRepertDispComplessivaSelez = params.row
      if (params.column.field == 'htmlurl_note') {
        this.apriNote(params)
      }
    },
    apriNote(params) {
      // empty string
      if (params.row.note != '') {
        Common.openDialogIframe(this, 'dialogiframe', 'Condizioni dello strato', params.row.note);
      }
    },
  }, 
    mounted() {
      var errorMounted = '';
      //Leggo username corrente
      Auth.getUsername()
        .then(AuthUsername => {
          this.AuthUsername = AuthUsername
        })
        .catch(function (error) {
          this.error = errorMounted;
        })
        .finally(() => {
          //Popolo grid disponibilità complessiva
          RepertCarto.getRepertAllRepertori(this.AuthUsername)
            .then(rowsTableRepertAllRepertori => {
              this.rowsTableRepertAllRepertori = rowsTableRepertAllRepertori.data
            })
            .catch(function (error) {
              this.error = errorMounted;
            })
            .finally(() => {
              this.loadingAllRepertori = false
              this.error = errorMounted;
              if (this.error != '') {
                console.error(this.error);
              }
            });
        })
    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only-->
<style scoped>
</style>
