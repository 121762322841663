<template>
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white fixed-top">
  <div class="container container-header-footer">

      <a class="navbar-brand linkhome" :href="VUE_APP_LINKHOME">
        <img :src="img_sctoutil_empty.src" :title="HTMLTITLE" class="img-thumbnail" v-if="!user.statoabbonamento">
        <img :src="img_sctoutil.src" :title="HTMLTITLE" class="img-thumbnail" v-if="user.statoabbonamento<2">
        <img :src="img_sctoutil_libre.src" :title="HTMLTITLE" class="img-thumbnail" v-if="user.statoabbonamento==2">
        <img :src="img_sctoutil_pro.src" :title="HTMLTITLE" class="img-thumbnail" v-if="user.statoabbonamento==3">
        <img :src="img_sctoutil_libre.src" :title="HTMLTITLE" class="img-thumbnail" v-if="user.statoabbonamento>3">
      </a>
      
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
          </li>

          <a class="nav-link hrefprogetto_auth" :href="VUE_APP_HREFPROGETTO">Informazioni</a>
          <li class="nav-item">
            <a class="nav-link hrefadesione_auth" :href="VUE_APP_HREFADESIONE">Adesione</a>
          </li>
          <li class="nav-item">
            <a class="nav-link hrefcontatti_auth" :href="VUE_APP_HREFCONTATTI">Contatti</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Servizi
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
              <a class="dropdown-item hrefcartageo" :href="VUE_APP_HREFCARTAGEO">Geonavigatore</a>
              <a class="dropdown-item hrefmappecat" :href="VUE_APP_HREFMAPPECAT">Servizio Catasto</a>
              <a class="dropdown-item hrefdownloadfiles" :href="VUE_APP_HREFDOWNLOADFILES">Strumenti</a>
              <a class="dropdown-item hrefrepertcarto" :href="VUE_APP_HREFREPERTCARTO">Repertorio Cartografico</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user fa-fw"></i>&nbsp;Il mio account
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
              <div class="dropdown-item">
                <p class="font-weight-bold"><span id="auth_cognnome">{{user.nome_cogn}}</span></p>
              </div>
              <a class="dropdown-item hrefstatoabbonamento" :href="VUE_APP_HREFSTATOABBONAMENTO">Stato dell'abbonamento</a>
              <a class="dropdown-item hrefanagrafica" :href="VUE_APP_HREFANAGRAFICA">Modifica e visualizza dati di registrazione</a>
              <a class="dropdown-item hrefinvioemail_auth" :href="VUE_APP_HREFINVIOEMAIL_AUTH">Invia segnalazione</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link hreflogout" :href="VUE_APP_HREFLOGOUT"><i class="fas fa-sign-out-alt"></i>&nbsp;Logout</a>
          </li>
        </ul>
      </div>
    
  </div>
</nav>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String,
    user: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  data() {
    
    return {
      img_sctoutil_empty: {
        src: require('@/images/Logo_SCT_Outil_empty.png')
      },
      img_sctoutil: {
        src: require('../../public/SCTProfessional/static/images/logo_sct_new.png')
      },
      img_sctoutil_libre: {
        src: require('@/images/Logo_SCT_Outil_Libre_Big.png')
      },
      img_sctoutil_pro: {
        src: require('@/images/Logo_SCT_Outil_Pro_Big.png')
      },
      img_regvda: {
        src: require('../../public/SCTProfessional/static/images/logo_ravdanew.png')
      },
      HTMLTITLE: process.env.VUE_APP_HTMLTITLE,
      VUE_APP_HREFPROGETTO: process.env.VUE_APP_HREFPROGETTO,
      VUE_APP_HREFADESIONE: process.env.VUE_APP_HREFADESIONE,
      VUE_APP_HREFCONTATTI: process.env.VUE_APP_HREFCONTATTI,
      VUE_APP_LINKHOME: process.env.VUE_APP_LINKHOME,
      VUE_APP_HREFCARTAGEO: process.env.VUE_APP_HREFCARTAGEO,
      VUE_APP_HREFMAPPECAT: process.env.VUE_APP_HREFMAPPECAT,
      VUE_APP_HREFDOWNLOADFILES: process.env.VUE_APP_HREFDOWNLOADFILES,
      VUE_APP_HREFREPERTCARTO: process.env.VUE_APP_HREFREPERTCARTO,
      VUE_APP_HREFSTATOABBONAMENTO: process.env.VUE_APP_HREFSTATOABBONAMENTO,
      VUE_APP_HREFANAGRAFICA: process.env.VUE_APP_HREFANAGRAFICA,
      VUE_APP_HREFINVIOEMAIL_AUTH: process.env.VUE_APP_HREFINVIOEMAIL_AUTH,
      VUE_APP_HREFLOGOUT: process.env.VUE_APP_HREFLOGOUT,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
-->
