/*Scripts per componenti di InvioSegnalazione*/ 
//recaptcha
import Vue from "vue";
import VueFormGenerator from "vue-form-generator";
import fieldRecaptcha from '@/components/VueRecaptcha.vue'
Vue.component("fieldRecaptcha", fieldRecaptcha);
Vue.use(VueFormGenerator);

export default {
  //definizione colonne per vue-form-generator per il form dei dati anagrafici
  //getSchemaInviaSegnalaz(allcomuni, allregioni, allprofessioni) {
  getSchemaInviaSegnalaz(tipoutilizzo) {
    //restituisco la struttura dello schema
    if(tipoutilizzo=='noauth'){
      return {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: 'Nome e Cognome',
            model: 'nome_cogn',
            id: "txtNomeCogn",
            placeholder: 'Nome e Cognome',
            featured: true,
            required: true,
            styleClasses: 'col-md-12',
            attributes: {
              input: {
                "maxlength": "40"
              }
            },
            validator: ["required"]
          },
          {
            type: 'input',
            inputType: 'email',
            label: 'E-Mail',
            model: 'email',
            id: "txtemail",
            placeholder: 'Indirizzo e-mail',
            featured: true,
            required: true,
            validator: ["email"],
            styleClasses: 'col-md-12',
            attributes: {
              input: {
                "maxlength": "40"
              }
            }
          },
          {
            type: "textArea",
            label: "Messaggio",
            model: "bodymail",
            hint: "Massimo 500 caratteri",
            featured: true,
            required: true,
            max: 500,
            id: "txtBody",
            placeholder: "Dettaglio della segnalazione",
            rows: 4,
            validator: ["required"],
            styleClasses: 'col-md-12',
          },
          {
            type: "recaptcha",
            label: "ReCAPTCHA",
            featured: true,
            model: 'recaptcha',
            styleClasses: 'col-md-12',
          },




        ]
      }
    }
    if (tipoutilizzo == 'auth') {
      return {
        fields: [
          {
            type: 'label',
            inputType: 'text',
            label: 'Nome e Cognome',
            model: 'nome_cogn',
            id: "txtNomeCogn",
            placeholder: 'Nome e Cognome',
            featured: true,
            styleClasses: 'col-md-12',
            attributes: {
              input: {
                "maxlength": "40"
              }
            }
          },
          {
            type: 'label',
            inputType: 'email',
            label: 'E-Mail',
            model: 'email',
            id: "txtemail",
            placeholder: 'Indirizzo e-mail',
            featured: true,
            styleClasses: 'col-md-12',
            attributes: {
              input: {
                "maxlength": "40"
              }
            }
          },
          {
            type: "textArea",
            label: "Messaggio",
            model: "bodymail",
            hint: "Massimo 500 caratteri",
            featured: true,
            required: true,
            max: 500,
            id: "txtBody",
            placeholder: "Dettaglio della segnalazione",
            rows: 4,
            validator: ["required"],
            styleClasses: 'col-md-12',
          },
          {
            type: "recaptcha",
            label: "ReCAPTCHA",
            featured: true,
            model: 'recaptcha',
            styleClasses: 'col-md-12',
          },




        ]
      }

    }
    return {
      fields: []
    }

  },
  getFormOptionsInviaSegnalaz() {
    return {
      validateAfterLoad: true,
      validateAfterChanged: true,
      //validateAsync: true,
    }
  },
  preparaJSon_crud(schema, model) {
    var arrjson = {};
    //console.log('SCHEMA',schema);
    //console.log('MODEL',model);
    var type = '';
    for (var i = 0; i < schema.fields.length; i++) {
      type = schema.fields[i].type.toUpperCase();
      if (type == 'INPUT' || type == 'SELECT' || type == 'LABEL' || type == 'TEXTAREA') {
        arrjson[schema.fields[i].model] = model[schema.fields[i].model];
      }
    }
    //arrjson['g-recaptcha-response'] = encodeURIComponent(grecaptcha.getResponse());
    arrjson['g-recaptcha-response'] = grecaptcha.getResponse();
    return arrjson;
  },



}

