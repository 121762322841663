import axios from 'axios'

//restituisco tutte le news
function getNewsUrl() {
  var url = '/SCTProfessional/default/call/json/get_all_news';
  /*
  if (process.env.NODE_ENV === 'development') {
      url = 'http://localhost:8000' + url + '_svi';
  }
  */
  return url;
}

export default {
  getAllNews() {
    return axios
      .get(getNewsUrl())
      .then(response => {
        return response.data.allnews;
      })
  }
}
